<template>
    <div class="card flex justify-content-center">
        <Toast />
    </div>
</template>
<script>

import Toast from "primevue/toast"

export default {
    components: {
        Toast,
    },
}

</script>
