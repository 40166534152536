<template>
    <auditor-select-box
        :calculation-tool-id-prop="calculationToolIdProp"
        :is-single-auditor-prop="false"
    />
</template>

<script>
import CalculationTool from '../../../../CalculationTool/CalculationTool';
import _ from "lodash";
import AuditorSelectBox from "../../AuditorSelectBox/Vue/AuditorSelectBox.vue";

export default {
    components: {AuditorSelectBox},
    props: {
        calculationToolIdProp: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            selectedAuditors: [],
        };
    },
    watch: {
        selectedAuditors() {
            window.mitt.emit("actionSelectedAuditors", this.selectedAuditors);
        }
    },
    mounted() {
        CalculationTool.get(this.calculationToolIdProp);

        window.mitt.on('actionGetSelectedAuditors', (actionData) => {
            this.selectedAuditors = actionData;
        });

        window.mitt.on('actionGetAuditors', (actionData) => {
            this.auditors = actionData;
        });

        window.mitt.on('actionAuditorsConfirmation', () => {
            this.confirmAddAuditors();
        });
    },
    methods: {
        confirmAddAuditors() {
            if (this.selectedAuditors.length) {
                let selectedAuditorsObj = _.filter(this.auditors, function (auditor) {
                    return this.selectedAuditors.includes(auditor.id) ;
                }.bind({selectedAuditors: this.selectedAuditors}));

                let body = '';

                _.forEach(selectedAuditorsObj, function(value) {
                    body += '• ' + value.name + '\n'
                });

                this.$confirm.require({
                    header: this.translate('application.quotation_request.confirmation_dialog.title'),
                    message: body,
                    acceptLabel: this.translate('application.quotation_request.confirmation_dialog.confirm_button_text'),
                    rejectLabel: this.translate('application.quotation_request.confirmation_dialog.cancel_button_text'),
                    defaultFocus: false,
                    accept: () => {
                        this.addAuditorsToCalculationTool(this.selectedAuditors, {
                            actionType: 'addAuditors',
                            entityData: this.selectedAuditors,
                        });
                    },
                });
            }
        },
        addAuditorsToCalculationTool(selectedAuditors, actionData) {
            if (actionData.actionType !== 'addAuditors' || selectedAuditors !== actionData.entityData) {
                return;
            }
            CalculationTool.sendQuotationToAuditors(this.calculationToolIdProp, {ids: selectedAuditors}).then(() => {
                window.mitt.emit("goToTheNextPage", {});
            });

        }
    },
};
</script>
<style>
.p-confirm-dialog-message {
    white-space: pre-line;
}
</style>
