<template>
    <div class="mt-10">
        <div class="bg-gray-050 rounded-lg drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)] p-8">
            <div>
                <h3 class="font-medium text-xl">
                    {{ translate('application.final_request.auditor.title') }}: <span class="ml-2">{{ calculationTool.ci?.name }}</span>
                </h3>
            </div>
            <div class="mt-7 flex">
                <div class="mr-16 text-md text-gray-900">
                    {{ translate('application.final_request.auditor.contact') }}
                </div>
                <div>
                    {{ calculationTool.ci?.main_contact_name }}
                </div>
                <div class="mx-3">
                    |
                </div>
                <div>
                    {{ calculationTool.ci?.email }}
                </div>
                <div class="mx-3">
                    |
                </div>
                <div>
                    {{ calculationTool.ci?.phone }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {mapGetters} from "vuex";
import _ from "lodash";

export default {
    props: {
        calculationToolIdProp: {
            type: String,
            default: ''
        }
    },
    computed: {
        ...mapGetters("calculation_tool", {calculationTool: "calculation_tool"}),
    },
};
</script>
