import '../sass/app.scss';
import FormInput from './Components/FormInput/FormInput'
import Base from './Components/Base';
import User from './Components/User/User';
import Dashboard from './Components/Dashboard/Dashboard';
import CalculationTool from './Components/CalculationTool/CalculationTool';
import Organisation from './Components/Organisation/Organisation';
import Tools from './Components/Tools/Tools';
import CalculationToolData from './Components/CalculationToolData/CalculationToolData';
import Score from './Components/Score/Score';
import Checklist from './Components/Checklist/Checklist'
import Application from "./Components/Application/Application";
import Advisor from "./Components/Advisor/Advisor";
import mitt from 'mitt';

window.mitt = window.mitt || new mitt();

const PSO = {};

PSO.FormInput = FormInput;
PSO.Base = Base;
PSO.User = User;
PSO.Tools = Tools;
PSO.Dashboard = Dashboard;
PSO.CalculationTool = CalculationTool;
PSO.Organisation = Organisation;
PSO.CalculationToolData = CalculationToolData;
PSO.Score = Score;
PSO.Checklist = Checklist;
PSO.Application = Application;
PSO.Advisor = Advisor;

PSO.Base.getTranslations();
try {
    renderComponent(PSO);
} catch (e) {
    console.warn('renderComponent was not found!');
}

import.meta.glob([
    '../images/**',
]);
