<template>
    <div class="page-select-an-auditor">
        <h2 class="text-center text-pso-dark text-2xl font-bold mb-10">
            {{ translate('application.request_selection.make_selection.title') }}
        </h2>
        <p class="font-bold">
            {{ translate('application.request_selection.make_selection.subtitle') }}
        </p>
        <ul class="list-disc pl-7">
            <li>
                {{ translate('application.request_selection.make_selection.bullet_point_1_first_part') }}
                <a
                    class="text-pso-primary"
                    target="_blank"
                    :href="links.what_does_the_pso_quality_mark_cost"
                >
                    {{ translate('application.request_selection.make_selection.bullet_point_1__link') }}
                </a>
                {{ translate('application.request_selection.make_selection.bullet_point_1_second_part') }}
            </li>
            <li>
                {{ translate('application.request_selection.make_selection.bullet_point_2') }}
            </li>
            <li>
                {{ translate('application.request_selection.make_selection.bullet_point_3') }}
            </li>
        </ul>
        <p>
            {{ translate('application.request_selection.make_selection.last_paragraph') }}
            <a
                class="text-pso-primary"
                target="_blank"
                :href="links.pso_avg"
            >
                {{ translate('application.request_selection.make_selection.last_paragraph_link') }}
            </a>.
        </p>

        <p class="mt-10">
            {{ translate('application.request_selection.make_selection.question') }}
        </p>

        <div class="flex h-12 mt-6 justify-content-center">
            <div
                class="flex w-1/2 flex-row-reverse justify-end justify-content-center items-center border rounded-l mr-4"
                :class="isFinalApplicationRequest === false ? 'border-blue-800 bg-blue-200' : 'border-gray-200'"
            >
                <label
                    for="request_quote_checkbox"
                    class="w-full text-md text-black items-center mb-0 pl-2 pt-3 pb-3 pl-3 pr-20 hover:cursor-pointer"
                >
                    {{ translate('application.request_selection.make_selection.request_quote_checkbox') }}
                </label>
                <input
                    id="request_quote_checkbox"
                    v-model="isFinalApplicationRequest"
                    type="radio"
                    class="form-radio appearance-none rounded-full h-4 w-4 border border-gray-200 bg-white text-pso-primary checked:text-pso-primary focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 ml-4 cursor-pointer "
                    name="selection"
                    :value="false"
                    @change="setNextStep()"
                >
            </div>

            <div
                class="flex w-1/2 flex-row-reverse justify-end items-center border rounded-l"
                :class="isFinalApplicationRequest === true ? 'border-blue-800 bg-blue-200' : 'border-gray-200'"
            >
                <label
                    for="final_request_auditors_checkbox"
                    class="w-full text-md text-black items-center mb-0 pl-2 pt-3 pb-3 pl-3 pr-20 hover:cursor-pointer"
                >
                    {{ translate('application.request_selection.make_selection.final_request_auditors_checkbox') }}
                </label>
                <input
                    id="final_request_auditors_checkbox"
                    v-model="isFinalApplicationRequest"
                    type="radio"
                    class="form-radio appearance-none rounded-full h-4 w-4 border border-gray-200 bg-white text-blue-800 checked:text-pso-primary focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 ml-4 cursor-pointer"
                    name="selection"
                    :value="true"
                    @change="setNextStep()"
                >
            </div>
        </div>
        <div class="action-button text-center mt-10">
            <button
                class="inline-block mx-auto py-3 px-8 rounded-xl font-medium text-white bg-pso-orange"
                :class="isFinalApplicationRequest !== null ? '' : 'opacity-50 cursor-not-allowed'"
                dusk="next-page-button"
                @click.prevent="goToNextStep()"
            >
                {{ translate('application.request_selection.make_selection.next_step_button') }}
            </button>
        </div>
    </div>
</template>
<script>

import CalculationTool from "../../../../CalculationTool/CalculationTool";
import Links from "../../../../_config/Links.js";
import {getSteps} from "../../../../_config/Steps";

export default {
    props: {
        calculationToolIdProp: {
            type: Number,
            required: true,
        }
    },
    data() {
        return {
            links: Links,
            isFinalApplicationRequest: null,
            nextStepURL: '#',
        }
    },
    methods: {
        setNextStep() {
            if (this.isFinalApplicationRequest) {
                this.nextStepURL = route('calculation-tool-application.final-request-information', {
                    calculationTool: this.calculationToolIdProp,
                })
            } else {
                this.nextStepURL = route('calculation-tool-application.quotation-request', {
                    calculationTool: this.calculationToolIdProp,
                });
            }
        },
        goToNextStep() {
            if (this.isFinalApplicationRequest) {
                CalculationTool.updateStep({calculationTool: this.calculationToolIdProp, step: getSteps().final_application})
                    .catch((error) => {
                        console.log(error.response.data.data.message);
                    });
            } else {
                CalculationTool.updateStep({calculationTool: this.calculationToolIdProp, step: getSteps().quote_request})
                    .catch((error) => {
                        console.log(error.response.data.data.message);
                    });
            }

            window.location = (this.isFinalApplicationRequest !== null) ? this.nextStepURL : 'javascript:void(0)'
        }
    }
};
</script>
