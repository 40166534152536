import Base from "../Base";
import toasterMessage from "../Tools/Parts/ToasterMessage";
import vueWizard from "../Tools/Parts/Wizard";
import vueTimeline from "./Parts/Timeline";
import vueScoreBar from "./Parts/ScoreBar";
import vueAuditorSwitchButton from "./Parts/AuditorSwitchButton";
import confirmationDialog from "./Parts/ConfirmationDialog";
import dialogMessage from "./Parts/DialogMessage";

class Tools extends Base {
    static getComponents() {
        return {
            "toaster-message": toasterMessage,
            "confirmation-dialog": confirmationDialog,
            "dialog-message": dialogMessage,
            "wizard-form": vueWizard,
            "timeline-widget": vueTimeline,
            "score-bar": vueScoreBar,
            "auditor-switch-button": vueAuditorSwitchButton,
        };
    }
}

export default Tools;
