import {createApp} from 'vue';
import _ from "lodash";
import psoDashboard from "./Vue/PsoDashboard.vue";

export default (selector, options) => {
    let vueOptions = {
        el: selector,
        components: {psoDashboard},
    }

    return createApp(_.merge(options, vueOptions));
}
