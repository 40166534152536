<template>
    <div class="mt-10">
        <h2 class="text-center text-pso-dark text-xl font-bold">
            {{ translate('application.additional_information.contact.title') }}
        </h2>
        <div>
            <div
                v-for="(organisation, index) in organisationsList"
                :key="index"
            >
                <organisation-contact
                    :organisation-prop="organisation.data"
                    :index-prop="index"
                    :calculation-tool-id-prop="calculationToolIdProp"
                />
            </div>
        </div>
    </div>
</template>

<script>

import {mapGetters} from "vuex";
import OrganisationContact from '../../OrganisationContact/Vue/OrganisationContact.vue';

export default {
    components: {
        OrganisationContact,
    },
    props: {
        calculationToolIdProp: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            organisationsList: [],
        };
    },
    computed: {
        ...mapGetters("calculation_tool", {receivedOrganisationsList: "organisations_list"}),
    },
    watch: {
        receivedOrganisationsList() {
            this.organisationsList = this.receivedOrganisationsList;
        },
    },
};
</script>
