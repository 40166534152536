<template>
    <div class="page">
        <div class="container mx-auto">
            <calculation-tool-header />
            <div class="max-w-[1108px] mx-auto bg-white rounded-xl shadow pt-12 pb-12 pl-12 pr-12 px-12">
                <div>
                    <app-back-button
                        href="#"
                        @click.prevent="previousStep()"
                    />
                </div>
                <h2 class="text-center text-pso-dark text-2xl font-bold mb-10">
                    {{ translate('application.final_request.title') }}
                </h2>

                <calculation-tool-overview
                    :calculation-tool-id-prop="calculationToolIdProp"
                />

                <auditor-grey-block :calculation-tool-id-prop="calculationToolIdProp" />

                <p class="mt-10 font-medium text-xl">
                    {{ translate('application.final_request.auditor.contract_paragraph') }}
                </p>

                <div
                    :class="pdfFullScreen ? 'fixed top-0 left-0 h-full w-full z-30 h-full' : 'relative mt-6'"
                >
                    <div
                        class="text-center overflow-y-auto overflow-x-hidden border-pso-primary border-[3px] bg-white"
                        :class="pdfFullScreen ? 'h-full' : 'h-[623px] drop-shadow-[0_2px_4px_rgba(6,69,106,0.1)] rounded-lg'"
                    >
                        <div
                            v-for="page in pages"
                            :key="page"
                        >
                            <VuePDF
                                class="justify-center"
                                :pdf="pdf"
                                :page="page"
                                fit-parent
                            />
                        </div>
                    </div>
                    <span
                        class="absolute top-8 right-12 hover:cursor-pointer z-40"
                        @click.prevent="makePdfFullScreen"
                    >
                        <img
                            :src="arrows"
                            width="24"
                        >
                    </span>
                </div>
                <div class="flex mt-4 justify-between">
                    <span class="font-medium text-xl pt-2">
                        {{ translate('application.final_request.signature') }}
                    </span>
                    <a
                        :href="pdfFile"
                        class="border rounded-lg text-pso-dark font-medium border-pso-dark px-4 py-2"
                        target="_blank"
                        download
                    >
                        {{ translate('application.final_request.download_pdf') }}
                    </a>
                </div>
                <div class="relative mb-10">
                    <div class="border-pso-primary border-[3px] rounded-xl w-[365px] relative">
                        <VueSignaturePad
                            ref="signaturePad"
                            width="360px"
                            height="135px"
                            :options="{penColor: '#393C3F'}"
                            dusk="signature-pad"
                        />
                        <button
                            class="absolute right-4 top-3"
                            @click="clearSignature"
                        >
                            <img
                                :src="deleteIcon"
                                width="16"
                            >
                        </button>
                        <div class="text-center py-2 text-gray-800">
                            {{ translate('application.final_request.date') }}: {{ currentDate }}
                        </div>
                    </div>
                    <p
                        v-if="signatureError"
                        class="text-red-700"
                    >
                        {{ translate('application.final_request.required_signature') }}
                    </p>
                </div>

                <div class="mb-10">
                    <div>
                        {{ translate('application.final_request.recertification') }}

                        <Popper
                            :arrow="true"
                            placement="top"
                        >
                            <div class="ml-1">
                                <img
                                    :src="iconInfo"
                                    alt="info"
                                >
                            </div>
                            <template #content>
                                {{ translate('application.final_request.tooltip_message') }}
                            </template>
                        </Popper>
                    </div>

                    <div class="questions flex w-1/3 mt-4">
                        <div
                            class="radio-button-wrapper flex flex-row-reverse justify-end items-center border rounded-xl mr-4"
                            :class="recertification == '1' ? 'border-blue-800 bg-blue-200' : 'border-gray-200'"
                        >
                            <label
                                for="confirmationYes"
                                class="text-md text-black items-center mb-0 pl-2 pt-3 pb-3 pl-3 w-1/3 pr-20 hover:cursor-pointer"
                            >
                                {{ translate('organisation.kvk.more_entities_question.yes') }}
                            </label>
                            <input
                                id="confirmationYes"
                                v-model="recertification"
                                type="radio"
                                class="form-radio appearance-none rounded-full h-4 w-4 border border-gray-200 bg-white text-blue-800 checked:text-pso-primary focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 ml-4 cursor-pointer "
                                name="confirmation"
                                value="1"
                                @change="checkAllRequired()"
                                dusk="recertification-yes"
                            >
                        </div>

                        <div
                            class="radio-button-wrapper flex flex-row-reverse justify-end items-center border rounded-xl"
                            :class="recertification == '0' ? 'border-blue-800 bg-blue-200' : 'border-gray-200'"
                        >
                            <label
                                for="confirmationNo"
                                class="text-md text-black items-center mb-0 pl-2 pt-3 pb-3 pl-3 w-1/3 pr-20 hover:cursor-pointer"
                            >
                                {{ translate('organisation.kvk.more_entities_question.no') }}
                            </label>
                            <input
                                id="confirmationNo"
                                v-model="recertification"
                                type="radio"
                                class="form-radio appearance-none rounded-full h-4 w-4 border border-gray-200 bg-white text-blue-800 checked:text-pso-primary focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 ml-4 cursor-pointer"
                                name="confirmation"
                                value="0"
                                @change="checkAllRequired()"
                                dusk="recertification-no"
                            >
                        </div>
                    </div>
                </div>

                <div class="mb-10 flex items-center">
                    <input
                        id="agree-conditions"
                        v-model="agreeConditions"
                        type="checkbox"
                        class="mr-4 border-gray-400 rounded-sm bg-white text-blue-800 checked:text-pso-primary focus:outline-none transition duration-200 bg-no-repeat bg-center bg-contain cursor-pointer"
                        value="1"
                        dusk="agree-to-terms-checkbox"
                        @change="checkAllRequired()"
                    >
                    <label for="agree-conditions">
                        {{ translate('application.final_request.checkbox_text') }}
                    </label>
                </div>

                <div class="action-button text-center mt-10">
                    <button
                        class="inline-block mx-auto py-3 px-8 rounded-xl font-medium text-white bg-pso-orange"
                        :class="checkRequired ? '' : 'opacity-50 cursor-not-allowed'"
                        dusk="go-to-the-next-step"
                        @click.prevent="storeAndGoToTheNextPage()"
                    >
                        {{ translate('application.final_request.button') }}
                    </button>
                    <span
                        v-if="errorMessage"
                        class="text-sm text-red-500 block w-full mt-3"
                        role="alert"
                        v-text="errorMessage"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import CalculationToolHeader from "../../../CalculationTool/Parts/CalculationToolHeader/Vue/CalculationToolHeader.vue";
import CalculationTool from "../../../CalculationTool/CalculationTool";
import {mapGetters} from 'vuex';
import BackLinkButton from "../../../Templates/Parts/BackLinkButton/Vue/BackLinkButton.vue";
import CalculationToolOverview from "../../../CalculationTool/Parts/CalculationToolOverview/Vue/CalculationToolOverview.vue";
import AuditorGreyBlock from "../../../Auditor/Parts/AuditorGreyBlock/Vue/AuditorGreyBlock.vue";
import {usePDF, VuePDF} from '@tato30/vue-pdf'
import { VueSignaturePad } from 'vue-signature-pad';
import Popper from 'vue3-popper';
import Score from "../../../Score/Score";
import {getStatuses} from "../../../_config/Statuses";

export default {
    components: {
        VueSignaturePad,
        AuditorGreyBlock,
        CalculationToolHeader,
        CalculationToolOverview,
        VuePDF,
        'app-back-button': BackLinkButton,
        Popper
    },
    props: {
        calculationToolIdProp: {
            type: Number,
            required: true
        },
    },
    setup(){
        const { pdf, pages } = usePDF(new URL('/resources/files/Deelnameovereenkomst_en_Algemene_Voorwaarden_PSO_Nederland.pdf', import.meta.url).href)

        return {
            pdf,
            pages
        };
    },
    data() {
        return {
            pdfFullScreen: false,
            checkRequired: false,
            currentDate: '',
            recertification: '',
            agreeConditions: '',
            signatureError: false,
            errorMessage: '',
            errors: {},
            pdfFile: new URL('/resources/files/Deelnameovereenkomst_en_Algemene_Voorwaarden_PSO_Nederland.pdf', import.meta.url).href,
            arrows: new URL('/resources/images/enlarge_icon.svg', import.meta.url).href,
            deleteIcon: new URL('/resources/images/icon_delete_blue.svg', import.meta.url).href,
            iconInfo: new URL('/resources/images/icon_info.svg', import.meta.url).href,
            statuses: getStatuses()
        };
    },
    computed: {
        ...mapGetters('calculation_tool', {calculationTool: 'calculation_tool'}),
    },
    watch: {
        calculationTool() {
            this.recertification = this.calculationTool.recertification;
        }
    },
    mounted() {
        CalculationTool.get(this.calculationToolIdProp);
        var date = new Date();
        this.currentDate =  date.getDate()+ ' ' + date.toLocaleString('default', { month: 'short' }) + ' ' + date.getFullYear();
    },
    methods: {
        storeAndGoToTheNextPage() {
            this.errorMessage = '';
            this.signatureError = false;
            let signature = this.getSignature();
            if(!signature){
                this.signatureError = true;
            } else {
                CalculationTool.validate(this.calculationToolIdProp).then((response) => {
                    if (response.message === 'success') {
                        this.updateCalculationToolForm(signature)
                    }
                }).catch((error) => {
                    if (error) {
                        if(422 === error.response.status){
                            this.$setErrorsFromResponse(error.response.data);

                            let dialogData = {
                                body: this.errors.reference_date,
                                buttons: [{
                                    text: this.translate('calculation_tool.validate.dialog.button'),
                                    redirect: route('calculation-tool-data.reference-date-cao-hours', this.calculationToolIdProp)
                                }]
                            }
                            window.mitt.emit("dialogMessage", dialogData);
                        }
                    }
                });
            }
        },
        updateCalculationToolForm(signature) {
            // @TODO we might need to add that only 1 calculation tool can be in review at a time.
            let formData = CalculationTool.buildUpdateCalculationToolForm({
                id: this.calculationToolIdProp,
                signature: signature,
                recertification: this.recertification,
                status: this.statuses.FINAL
            });

            CalculationTool.updateCalculationToolForm(formData)
                .then(() => {
                    return Score.createResult(this.calculationToolIdProp);
                })
                .then(() => {
                    let dialogData = {
                        header: this.translate('application.final_request.success_message_modal.title'),
                        body: this.translate('application.final_request.success_message_modal.message'),
                        buttons: [{
                            text: this.translate('application.final_request.success_message_modal.button'),
                            redirect: route('dashboard'),
                        }],
                        closable: true,
                    };
                    window.mitt.emit("dialogMessage", dialogData);
                    return CalculationTool.notify({ calculationTool: this.calculationToolIdProp });
                })
                .then(() => {
                    console.log('succeeded');
                })
                .catch((error) => {
                    if (error) {
                        this.errorMessage = error.response ? error.response.data.message : error.message;
                    }
                });
        },
        previousStep() {
            window.location = route('calculation-tool-application.request-selection', {calculationTool: this.calculationToolIdProp});
        },
        makePdfFullScreen() {
            this.pdfFullScreen = !this.pdfFullScreen;
        },
        clearSignature() {
            this.$refs.signaturePad.clearSignature();
        },
        getSignature() {
            const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
            if(!isEmpty){
                return data;
            }

            return false
        },
        checkAllRequired() {
            this.checkRequired = this.recertification !== '' && this.agreeConditions;
        }
    }
};
</script>
