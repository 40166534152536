<template>
    <div>
        <h2 class="text-center text-pso-dark text-2xl font-bold mb-10">
            {{ translate('application.request_selection.final_request_applied.title') }}
        </h2>
        <p>
            {{ translate('application.request_selection.final_request_applied.paragraph') }}
            <span class="font-bold">{{ calculationTool.name }}</span>
            {{ translate('application.request_selection.final_request_applied.paragraph2') }}
        </p>
        <div class="action-button text-center mt-10">
            <button
                class="inline-block bg-pso-orange cursor-pointer mx-auto py-3 px-8 rounded-xl font-medium text-white"
                dusk="next-page-button"
                @click="getNextStep()"
            >
                {{ translate('application.request_selection.final_request_applied.button') }}
            </button>
        </div>
    </div>
</template>
<script>


import {mapGetters} from "vuex";

export default {
    props: {
        calculationToolIdProp: {
            type: Number,
            required: true,
        }
    },
    computed: {
        ...mapGetters("calculation_tool", {calculationTool: "calculation_tool"}),
    },
    methods: {
        getNextStep() {
            window.location = route('dashboard');
        },
    }
};
</script>
