<template>
    <div class="page">
        <div class="container mx-auto">
            <calculation-tool-header />
            <div class="max-w-[1108px] mx-auto bg-white rounded-xl shadow pt-12 pb-12 pl-12 pr-12 px-12">
                <div>
                    <app-back-button
                        href="#"
                        @click.prevent="previousStep()"
                    />
                </div>
                <h2 class="text-center text-pso-dark text-2xl font-bold mb-10">
                    {{ translate('application.final_request_information.title') }}
                </h2>

                <calculation-tool-overview
                    :calculation-tool-id-prop="calculationToolIdProp"
                    class="mb-10"
                />

                <div class="mb-10 border-blue-400 pb-10 border-b-2 border-solid">
                    <h3 class="text-pso-dark text-xl font-medium mb-4">
                        {{ translate('application.final_request_information.title_1') }}
                    </h3>
                    <p>
                        {{ translate('application.final_request_information.paragraph_1_1') }}
                    </p>
                    <ol class="ml-6 list-decimal">
                        <li>
                            {{ translate('application.final_request_information.paragraph_1_2') }}
                        </li>
                        <li>
                            {{ translate('application.final_request_information.paragraph_1_3') }}
                        </li>
                    </ol>

                    <p>
                        {{ translate('application.final_request_information.paragraph_1_4') }}
                    </p>
                    <p>
                        {{ translate('application.final_request_information.paragraph_1_5') }}
                        <a
                            :href="links.what_does_the_pso_quality_mark_cost"
                            target="_blank"
                            class="text-pso-primary underline"
                        >
                            {{ translate('application.final_request_information.link_text') }}
                        </a>.
                    </p>
                </div>
                <div class="mb-10">
                    <h3 class="text-pso-dark text-xl font-medium mb-4">
                        {{ translate('application.final_request_information.title_2') }}
                    </h3>
                    <p class="mb-10">
                        {{ translate('application.final_request_information.paragraph_2_1') }}
                    </p>
                    <div class="flex items-center mb-10">
                        <img
                            :src="iconCheckboxBlue"
                            alt="open"
                            class="w-4"
                        >
                        <p class="pl-5 text-lg text-pso-dark">
                            {{ translate('application.final_request_information.checklist_1') }}
                        </p>
                    </div>
                    <div class="flex items-center mb-10">
                        <img
                            :src="iconCheckboxBlue"
                            alt="open"
                            class="w-4"
                        >
                        <p class="pl-5 text-lg text-pso-dark">
                            {{ translate('application.final_request_information.checklist_2') }}
                        </p>
                    </div>
                    <div class="flex items-center mb-10">
                        <img
                            :src="iconCheckboxBlue"
                            alt="open"
                            class="w-4"
                        >
                        <p class="pl-5 text-lg text-pso-dark">
                            {{ translate('application.final_request_information.checklist_3') }}
                        </p>
                    </div>
                    <div class="flex items-center">
                        <img
                            :src="iconCheckboxBlue"
                            alt="open"
                            class="w-4"
                        >
                        <p class="pl-5 text-lg text-pso-dark">
                            {{ translate('application.final_request_information.checklist_4') }}
                        </p>
                    </div>
                </div>

                <div class="mb-10 flex items-center">
                    <input
                        id="agree-conditions"
                        v-model="agreedToConditions"
                        type="checkbox"
                        class="mr-4 border-gray-400 rounded-sm bg-white text-blue-800 checked:text-pso-primary focus:outline-none transition duration-200 bg-no-repeat bg-center bg-contain cursor-pointer"
                        value="1"
                    >
                    <label for="agree-conditions">
                        {{ translate('application.final_request_information.checkbox_text') }}
                    </label>
                </div>

                <div class="action-button text-center">
                    <button
                        class="inline-block mx-auto py-3 px-8 rounded-xl font-medium text-white bg-pso-orange"
                        :class="agreedToConditions ? '' : 'opacity-50 cursor-not-allowed'"
                        dusk="go-to-the-next-step"
                        @click.prevent="goToTheNextPage()"
                    >
                        {{ translate('application.final_request_information.button') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import CalculationToolHeader from "../../../CalculationTool/Parts/CalculationToolHeader/Vue/CalculationToolHeader.vue";
import CalculationTool from "../../../CalculationTool/CalculationTool";
import {mapGetters} from 'vuex';
import BackLinkButton from "../../../Templates/Parts/BackLinkButton/Vue/BackLinkButton.vue";
import CalculationToolOverview from "../../../CalculationTool/Parts/CalculationToolOverview/Vue/CalculationToolOverview.vue";
import Links from "../../../_config/Links.js";

export default {
    components: {
        CalculationToolHeader,
        CalculationToolOverview,
        'app-back-button': BackLinkButton,
    },
    props: {
        calculationToolIdProp: {
            type: Number,
            required: true,
        },
    },

    data() {
        return {
            links: Links,
            agreedToConditions: '',
            iconCheckboxBlue: new URL('/resources/images/check_blue.svg', import.meta.url).href,
        };
    },
    computed: {
        ...mapGetters('calculation_tool', {calculationTool: 'calculation_tool'}),
    },
    mounted() {
        CalculationTool.get(this.calculationToolIdProp);
    },
    methods: {
        goToTheNextPage() {
            if(this.agreedToConditions){
                window.location = route('calculation-tool-application.final-request-auditors', {calculationTool: this.calculationToolIdProp});
            }
        },
        previousStep() {
            window.location = route('calculation-tool-application.request-selection', {calculationTool: this.calculationToolIdProp});
        }
    }
};
</script>
