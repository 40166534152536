import { createApp } from 'vue';
import _ from "lodash";
import numberInputWithAuditor from "./Vue/NumberInputWithAuditor.vue";

export default (selector, options) => {
    let vueOptions = {
        el: selector,
        components: {numberInputWithAuditor},
    }

    return createApp(_.merge(options, vueOptions));
}
