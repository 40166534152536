import {createApp} from 'vue';
import _ from "lodash";
import auditorDashboard from "./Vue/AuditorDashboard.vue";

export default (selector, options) => {
    let vueOptions = {
        el: selector,
        components: {auditorDashboard},
    }

    return createApp(_.merge(options, vueOptions));
}
