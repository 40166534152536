<template>
    <div class="mt-10">
        <h2 class="text-center text-pso-dark text-xl font-bold">
            {{ translate('application.additional_information.billing.title') }}
        </h2>
        <div class="mt-10">
            <div
                class="pb-2 pt-2 flex items-center text-md"
            >
                <input
                    id="billing_checkbox"
                    v-model="billing_checkbox"
                    class="align-text-bottom mr-4 border-gray-400 rounded-sm bg-white text-blue-800 checked:text-pso-primary focus:outline-none transition duration-200 bg-no-repeat bg-center bg-contain cursor-pointer"
                    type="checkbox"
                    value="1"
                    name="billing_checkbox"
                    :checked="true"
                    @click="invoiceSameAsOrganisation()"
                >
                <label
                    for="billing_checkbox"
                >
                    {{ translate('application.additional_information.billing.checkbox_label') }}
                </label>
            </div>
            <div class="mt-10">
                {{ translate('application.additional_information.billing.company_name') }}
            </div>
            <div
                class="w-1/2"
            >
                <div class="mr-2">
                    <input
                        id="company_name"
                        v-model="form.company_name"
                        :class="{
                            'border-red-500': errors.street_name,
                            'bg-gray-100': billing_checkbox,
                        }"
                        :disabled="billing_checkbox"
                        :placeholder="translate('application.additional_information.billing.company_name')"
                        autocomplete=""
                        class="form-input px-6 py-3 rounded-xl w-full border-gray-200 text-pso-dark text-lg placeholder:text-gray-600"
                        name="billing_company_name"
                        type="text"
                    >
                    <span
                        v-if="errors.company_name"
                        class="text-sm text-red-500"
                        role="alert"
                        v-text="errors.company_name"
                    />
                </div>
            </div>
            <div class="mt-6">
                {{ translate('application.additional_information.billing.address') }}
            </div>
            <div class="flex mt-2">
                <div
                    class="w-1/2"
                >
                    <div class="mr-2">
                        <input
                            v-model="form.street_name"
                            :class="{
                                'border-red-500': errors.street_name,
                                'bg-gray-100': billing_checkbox,
                            }"
                            :disabled="billing_checkbox"
                            :placeholder="translate('application.additional_information.billing.street_name_placeholder')"
                            autocomplete=""
                            class="form-input px-6 py-3 rounded-xl w-full border-gray-200 text-pso-dark text-lg placeholder:text-gray-600"
                            name="billing_street_name"
                            type="text"
                        >
                        <span
                            v-if="errors.street_name"
                            class="text-sm text-red-500"
                            role="alert"
                            v-text="errors.street_name"
                        />
                    </div>
                </div>
                <div
                    class="w-1/4"
                >
                    <div class="mr-2 ml-2">
                        <input
                            v-model="form.house_number"
                            :class="{
                                'border-red-500': errors.street_name,
                                'bg-gray-100': billing_checkbox,
                            }"
                            :disabled="billing_checkbox"
                            :placeholder="translate('application.additional_information.billing.house_number_placeholder')"
                            autocomplete=""
                            class="form-input px-6 py-3 rounded-xl w-full border-gray-200 text-pso-dark text-lg placeholder:text-gray-600"
                            name="billing_street_number"
                            type="text"
                        >
                        <span
                            v-if="errors.house_number"
                            class="text-sm text-red-500"
                            role="alert"
                            v-text="errors.house_number"
                        />
                    </div>
                </div>
                <div
                    class="w-1/4"
                >
                    <div class="mr-1">
                        <input
                            v-model="form.addition"
                            :class="{
                                'border-red-500': errors.street_name,
                                'bg-gray-100': billing_checkbox,
                            }"
                            :disabled="billing_checkbox"
                            :placeholder="translate('application.additional_information.billing.addition_placeholder')"
                            autocomplete=""
                            class="form-input px-6 py-3 rounded-xl w-full border-gray-200 text-pso-dark text-lg placeholder:text-gray-600"
                            name="billing_addition"
                            type="text"
                        >
                        <span
                            v-if="errors.addition"
                            class="text-sm text-red-500"
                            role="alert"
                            v-text="errors.addition"
                        />
                    </div>
                </div>
            </div>
            <div class="flex mt-4">
                <div
                    class="w-1/2"
                >
                    <div class="mr-2">
                        <input
                            v-model="form.postal_code"
                            :class="{
                                'border-red-500': errors.street_name,
                                'bg-gray-100': billing_checkbox,
                            }"
                            :disabled="billing_checkbox"
                            :placeholder="translate('application.additional_information.billing.postal_code_placeholder')"
                            autocomplete=""
                            class="form-input px-6 py-3 rounded-xl w-full border-gray-200 text-pso-dark text-lg placeholder:text-gray-600"
                            name="billing_post_code"
                            type="text"
                        >
                        <span
                            v-if="errors.postal_code"
                            class="text-sm text-red-500"
                            role="alert"
                            v-text="errors.postal_code"
                        />
                    </div>
                </div>
                <div
                    class="w-1/2"
                >
                    <div class="ml-2">
                        <input
                            v-model="form.place_name"
                            :class="{
                                'border-red-500': errors.street_name,
                                'bg-gray-100': billing_checkbox,
                            }"
                            :disabled="billing_checkbox"
                            :placeholder="translate('application.additional_information.billing.city')"
                            autocomplete=""
                            class="form-input px-6 py-3 rounded-xl w-full border-gray-200 text-pso-dark text-lg placeholder:text-gray-600"
                            name="billing_city"
                            type="text"
                        >
                        <span
                            v-if="errors.place_name"
                            class="text-sm text-red-500"
                            role="alert"
                            v-text="errors.place_name"
                        />
                    </div>
                </div>
            </div>
            <div class="flex mt-4">
                <div
                    class="w-1/2"
                >
                    <div>
                        {{ translate('application.additional_information.billing.name') }}
                    </div>
                    <div class="mr-2 mt-2">
                        <input
                            v-model="form.receiver_name"
                            :class="{'border-red-500': errors.receiver_name}"
                            :placeholder="translate('application.additional_information.billing.name')"
                            autocomplete=""
                            class="form-input px-6 py-3 rounded-xl w-full border-gray-200 text-pso-dark text-lg placeholder:text-gray-600"
                            name="billing_name"
                            type="text"
                        >
                        <span
                            v-if="errors.receiver_name"
                            class="text-sm text-red-500"
                            role="alert"
                            v-text="errors.receiver_name"
                        />
                    </div>
                </div>
                <div
                    class="w-1/2"
                >
                    <div class="ml-2">
                        {{ translate('application.additional_information.billing.email') }}
                    </div>
                    <div class="ml-2 mt-2">
                        <input
                            v-model="form.invoice_email"
                            :class="{'border-red-500': errors.invoice_email}"
                            :placeholder="translate('application.additional_information.billing.email')"
                            autocomplete=""
                            class="form-input px-6 py-3 rounded-xl w-full border-gray-200 text-pso-dark text-lg placeholder:text-gray-600"
                            name="billing_email"
                            type="text"
                        >
                        <span
                            v-if="errors.invoice_email"
                            class="text-sm text-red-500"
                            role="alert"
                            v-text="errors.invoice_email"
                        />
                    </div>
                </div>
            </div>
            <div class="flex mt-4">
                <div
                    class="w-1/2"
                >
                    <div>
                        {{ translate('application.additional_information.billing.postal_office') }}
                    </div>
                    <div class="mr-2 mt-2">
                        <input
                            v-model="form.mailbox"
                            :class="{'border-red-500': errors.mailbox}"
                            :placeholder="translate('application.additional_information.billing.postal_office')"
                            autocomplete=""
                            class="form-input px-6 py-3 rounded-xl w-full border-gray-200 text-pso-dark text-lg placeholder:text-gray-600"
                            name="billing_postal_office"
                            type="text"
                        >
                        <span
                            v-if="errors.mailbox"
                            class="text-sm text-red-500"
                            role="alert"
                            v-text="errors.mailbox"
                        />
                    </div>
                </div>
            </div>
            <div class="flex mt-4">
                <div
                    class="w-1/2"
                >
                    <div>
                        {{ translate('application.additional_information.billing.postal_code') }}
                    </div>
                    <div class="mr-2 mt-2">
                        <input
                            v-model="form.mailbox_postal_code"
                            :class="{'border-red-500': errors.mailbox_postal_code}"
                            :placeholder="translate('application.additional_information.billing.postal_code')"
                            autocomplete=""
                            class="form-input px-6 py-3 rounded-xl w-full border-gray-200 text-pso-dark text-lg placeholder:text-gray-600"
                            name="billing_postal_code_postal_office"
                            type="text"
                        >
                        <span
                            v-if="errors.mailbox_postal_code"
                            class="text-sm text-red-500"
                            role="alert"
                            v-text="errors.mailbox_postal_code"
                        />
                    </div>
                </div>
                <div
                    class="w-1/2"
                >
                    <div class="ml-2">
                        {{ translate('application.additional_information.billing.city') }}
                    </div>
                    <div class="ml-2 mt-2">
                        <input
                            v-model="form.mailbox_place"
                            :class="{'border-red-500': errors.mailbox_place}"
                            :placeholder="translate('application.additional_information.billing.city')"
                            autocomplete=""
                            class="form-input px-6 py-3 rounded-xl w-full border-gray-200 text-pso-dark text-lg placeholder:text-gray-600"
                            name="billing_city_postal_office"
                            type="text"
                        >
                        <span
                            v-if="errors.mailbox_place"
                            class="text-sm text-red-500"
                            role="alert"
                            v-text="errors.mailbox_place"
                        />
                    </div>
                </div>
            </div>
            <div class="flex mt-4">
                <div
                    class="w-1/2"
                >
                    <div>
                        {{ translate('application.additional_information.billing.payment_reference') }}
                    </div>
                    <div class="mr-2 mt-2">
                        <input
                            v-model="form.payment_reference"
                            :class="{'border-red-500': errors.payment_reference}"
                            :placeholder="translate('application.additional_information.billing.payment_reference')"
                            autocomplete=""
                            class="form-input px-6 py-3 rounded-xl w-full border-gray-200 text-pso-dark text-lg placeholder:text-gray-600"
                            name="billing_payment_reference"
                            type="text"
                        >
                        <span
                            v-if="errors.payment_reference"
                            class="text-sm text-red-500"
                            role="alert"
                            v-text="errors.payment_reference"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {mapGetters} from "vuex";
import _ from "lodash";
import CalculationTool from "../../../../CalculationTool/CalculationTool";

export default {
    props: {
        calculationToolIdProp: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            form: {},
            organisation: {},
            invoiceDetailsLoaded: false,
            billing_checkbox: true,
            errors: {},
        };
    },
    computed: {
        ...mapGetters("calculation_tool", {receivedOrganisationsList: "organisations_list"}),
        ...mapGetters('calculation_tool', {invoiceDetails: 'invoice_details'}),
    },

    watch: {
        receivedOrganisationsList() {
            this.organisation = _.first(_.filter(this.receivedOrganisationsList, function (organisation) {
                return organisation.data.is_main;
            }));

            this.organisation = this.organisation.data;
            this.buildFormData();
        },
        invoiceDetails() {
            this.invoiceDetailsLoaded = true;
            if(this.invoiceDetails) {
                this.billing_checkbox = false;
            }
            this.buildFormData();
        }
    },
    created() {
        CalculationTool.getInvoiceDetails(this.calculationToolIdProp);

        window.mitt.on('saveInvoiceDetails', () => {
            let formData = CalculationTool.buildInvoiceDetailsForm(this.form);
            CalculationTool.updateInvoiceDetails(this.calculationToolIdProp, formData)
                .then((data) => {
                    this.errors = {};
                    window.mitt.emit("continueToNextStepFromStep6", {});
                })
                .catch((error) => {
                    if (error) {
                        if ([401, 404].includes(error.response.status)) {
                            this.failed = error.response.data.message;
                        }
                        if (422 === error.response.status) {
                            this.$setErrorsFromResponse(error.response.data);
                        }
                    }
                });
        });
    },
    methods: {
        buildFormData() {
            if (this.invoiceDetailsLoaded && this.organisation) {
                this.form.company_name = this.invoiceDetails ? this.invoiceDetails.company_name : this.organisation.company_name;
                this.form.street_name = this.invoiceDetails ? this.invoiceDetails.street_name : this.organisation.street_name;
                this.form.house_number = this.invoiceDetails ? this.invoiceDetails.house_number : this.organisation.street_number;
                this.form.mailbox_place = this.invoiceDetails ? this.invoiceDetails.mailbox_place : '';
                this.form.addition = this.invoiceDetails ? this.invoiceDetails.addition : this.organisation.addition;
                this.form.postal_code = this.invoiceDetails ? this.invoiceDetails.postal_code : this.organisation.post_code;
                this.form.place_name = this.invoiceDetails ? this.invoiceDetails.place_name : this.organisation.city;
                this.form.receiver_name = this.invoiceDetails ? this.invoiceDetails.receiver_name : '';
                this.form.invoice_email = this.invoiceDetails ? this.invoiceDetails.invoice_email : this.organisation.email;
                this.form.mailbox = this.invoiceDetails ? this.invoiceDetails.mailbox : '';
                this.form.mailbox_postal_code = this.invoiceDetails ? this.invoiceDetails.mailbox_postal_code : '';
                this.form.payment_reference = this.invoiceDetails ? this.invoiceDetails.payment_reference : '';
            }
        },
        invoiceSameAsOrganisation() {
            if(!this.billing_checkbox){
                this.form.company_name = this.organisation.company_name;
                this.form.street_name = this.organisation.street_name;
                this.form.house_number = this.organisation.street_number;
                this.form.addition = this.organisation.addition;
                this.form.postal_code = this.organisation.post_code;
                this.form.place_name = this.organisation.city;
                this.form.invoice_email = this.organisation.email;
            }
        }
    }
};
</script>
