<template>
    <div class="page">
        <div class="container mx-auto">
            <calculation-tool-header />

            <div class="max-w-[1108px] mx-auto bg-white rounded-xl shadow pt-12 pb-12 pl-12 pr-12 px-12">
                <div class="actions-top">
                    <h2 class="text-center text-pso-dark text-2xl font-bold mb-10">
                        {{ translate('application.quotation_request_submit.title') }}
                    </h2>
                    <p>
                        {{ translate('application.quotation_request_submit.paragraph') }}
                        <span class="font-bold">{{ naturalLanguageJoin(companiesNamesList, translate('application.quotation_request_submit.companies_conjunction')) }}</span>{{ translate('application.quotation_request_submit.paragraph2') }}
                    </p>
                    <div class="action-button text-center mt-10">
                        <button
                            class="inline-block bg-blue-1000 cursor-pointer mx-auto py-3 px-8 rounded-xl font-medium text-white"
                            dusk="next-page-button"
                            @click="getNextStep()"
                        >
                            {{ translate('application.quotation_request_submit.button') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>


import {mapGetters} from "vuex";
import _ from "lodash";
import CalculationTool from "../../../CalculationTool/CalculationTool";
import Score from "../../../Score/Score";

export default {
    props: {
        calculationToolIdProp: {
            type: Number,
            required: true,
        }
    },
    computed: {
        ...mapGetters("calculation_tool", {calculationToolAuditors: "requested_auditors_list"}),
    },
    data() {
        return {
            companiesNamesList: [],
        }
    },
    watch: {
        calculationToolAuditors() {
            if (Object.keys(this.calculationToolAuditors).length) {
                this.companiesNamesList = _.map(this.calculationToolAuditors, function(companyObject){
                    return companyObject.name;
                });
            }
        }
    },
    mounted() {
        CalculationTool.getRequestedAuditors(this.calculationToolIdProp);
    },
    methods: {
        getNextStep() {
            Score.createResult(this.calculationToolIdProp)
                .then(() => {
                    window.location = route('calculation-tool-application.final-request-auditors', {
                        calculationTool: this.calculationToolIdProp,
                    });
                })
                .catch((error) => {
                    if (error) {
                        this.errorMessage = error.response.data.message;
                    }
                });
        },
    }
};
</script>
