import {createApp} from 'vue';
import _ from "lodash";
import requestSelection from "./Vue/RequestSelection.vue";
import store from "../../../Store/store";

export default (selector, options) => {
    let vueOptions = {
        store,
        el: selector,
        components: {requestSelection: requestSelection},
    };

    return createApp(_.merge(options, vueOptions));
}
