<template>
    <ConfirmDialog />
</template>

<script>
import ConfirmDialog from "primevue/confirmdialog";

export default {
    components: {
        ConfirmDialog
    },
}
</script>
