<template>
    <div class="page welcome">
        <div class="container mx-auto">
            <div class="max-w-[1108px]  mx-auto pb-12">
                <div class="page-header text-blue-1000 font-bold text-3xl">
                    {{ translate('dashboard.welcome.title') }}
                </div>
            </div>
            <div class="max-w-[1108px]  mx-auto bg-white rounded-xl shadow pt-12 pb-12 pl-12 pr-12 px-12">
                <h2 class="text-center text-pso-dark text-2xl font-bold">
                    {{ translate('dashboard.welcome.welcome') }} {{ fullNameProp }}
                </h2>
                <p
                    class="text-pso-dark mt-10"
                    v-html="translate('dashboard.welcome.message', translateKeys)"
                />
                <div class="horizontal-timeline mt-10 relative max-w-[900px] m-auto flex justify-center">
                    <div class="w-1/6 relative z-10">
                        <div
                            class="flex items-center justify-center m-auto w-12 h-12 rounded-full bg-blue-200 text-gray-800 text-xl font-medium"
                        >
                            1
                        </div>
                        <span
                            class="text-gray-800 lg:text-lg md:text-lg sm:text-sm text-sm mt-4 absolute left-1/2 -translate-x-2/4 min-w-[130px] text-center"
                        >
                            {{ translate('dashboard.welcome.calculation_tools') }}
                        </span>
                    </div>
                    <div class="w-1/6 relative z-10">
                        <div
                            class="flex items-center justify-center m-auto w-12 h-12 rounded-full bg-blue-200 text-gray-800 text-xl font-medium"
                        >
                            2
                        </div>
                        <span
                            class="text-gray-800 lg:text-lg md:text-lg sm:text-sm text-sm mt-4 absolute left-1/2 -translate-x-2/4 min-w-[130px] text-center"
                        >
                            {{ translate('dashboard.welcome.organisation') }}
                        </span>
                    </div>
                    <div class="w-1/6 relative z-10">
                        <div
                            class="flex items-center justify-center m-auto w-12 h-12 rounded-full bg-blue-200 text-gray-800 text-xl font-medium"
                        >
                            3
                        </div>
                        <span
                            class="text-gray-800 lg:text-lg md:text-lg sm:text-sm text-sm mt-4 absolute left-1/2 -translate-x-2/4 min-w-[130px] text-center"
                        >
                            {{ translate('dashboard.welcome.fill') }}
                        </span>
                    </div>
                    <div class="w-1/6 relative z-10">
                        <div
                            class="flex items-center justify-center m-auto w-12 h-12 rounded-full bg-blue-200 text-gray-800 text-xl font-medium"
                        >
                            4
                        </div>
                        <span
                            class="text-gray-800 lg:text-lg md:text-lg sm:text-sm text-sm mt-4 absolute left-1/2 -translate-x-2/4 min-w-[130px] text-center"
                        >
                            {{ translate('dashboard.welcome.score') }}
                        </span>
                    </div>
                    <div class="w-1/6 relative z-10">
                        <div
                            class="flex items-center justify-center m-auto w-12 h-12 rounded-full bg-blue-200 text-gray-800 text-xl font-medium"
                        >
                            5
                        </div>
                        <span
                            class="text-gray-800 lg:text-lg md:text-lg sm:text-sm text-sm mt-4 absolute left-1/2 -translate-x-2/4 min-w-[130px] text-center"
                        >
                            {{ translate('dashboard.welcome.checklist') }}
                        </span>
                    </div>
                    <div class="w-1/6 relative z-10">
                        <div
                            class="flex items-center justify-center m-auto w-12 h-12 rounded-full bg-blue-200 text-gray-800 text-xl font-medium"
                        >
                            6
                        </div>
                        <span
                            class="text-gray-800 lg:text-lg md:text-lg sm:text-sm text-sm mt-4 absolute left-1/2 -translate-x-2/4 min-w-[130px] text-center"
                        >
                            {{ translate('dashboard.welcome.application') }}
                        </span>
                    </div>
                    <div class="h-1 bg-blue-200 w-4/5 absolute z-0 top-6" />
                </div>
                <div class="action-button text-center mt-[80px]">
                    <a
                        :href="stepOneRoute()"
                        class="inline-block mx-auto py-3 px-8 rounded-xl font-medium text-white bg-blue-1000"
                    >
                        {{ translate('dashboard.welcome.begin_step_1') }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import User from "../../../../User/User";

export default {
    props: {
        fullNameProp: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            translateKeys: {
                'faq': '<a class="text-blue-1000 underline hover:no-underline" href="/faq" target="_blank">' + this.translate('layout.faq') + '</a>',
                'contact': '<a class="text-blue-1000 underline hover:no-underline" href="/contact" target="_blank">' + this.translate('layout.contact') + '</a>'
            },
        };
    },
    methods: {
        stepOneRoute() {
            return route('dashboard');
        },
    }
};
</script>
