<template>
    <div>
        <h2 class="text-center text-pso-dark text-2xl font-bold mb-10">
            {{ translate('application.request_selection.offer_submitted.title') }}
        </h2>
        <p>
            {{ translate('application.request_selection.offer_submitted.paragraph') }}
            <span class="font-bold">{{ naturalLanguageJoin(companiesNamesList, translate('application.request_selection.offer_submitted.companies_conjunction')) }}</span>{{ translate('application.request_selection.offer_submitted.paragraph2') }}
        </p>
        <div class="action-button text-center mt-10">
            <button
                class="inline-block bg-blue-1000 cursor-pointer mx-auto py-3 px-8 rounded-xl font-medium text-white"
                dusk="next-page-button"
                @click="getNextStep()"
            >
                {{ translate('application.request_selection.offer_submitted.button') }}
            </button>
        </div>
    </div>
</template>
<script>


import {mapGetters} from "vuex";
import _ from "lodash";
import CalculationTool from "../../../../CalculationTool/CalculationTool";

export default {
    props: {
        calculationToolIdProp: {
            type: Number,
            required: true,
        }
    },
    computed: {
        ...mapGetters("calculation_tool", {calculationToolAuditors: "requested_auditors_list"}),
    },
    data() {
        return {
            companiesNamesList: [],
        }
    },
    watch: {
        calculationToolAuditors() {
            if (Object.keys(this.calculationToolAuditors).length) {
                this.companiesNamesList = _.map(this.calculationToolAuditors, function(companyObject){
                    return companyObject.name;
                });
            }
        }
    },
    mounted() {
        CalculationTool.getRequestedAuditors(this.calculationToolIdProp);
    },
    methods: {
        getNextStep() {
            window.location = route('calculation-tool-application.final-request-information', this.calculationToolIdProp);
        },
    }
};
</script>
