<template>
    <div class="page">
        <div class="container mx-auto">
            <calculation-tool-header />
            <div class="max-w-[1108px] mx-auto bg-white rounded-xl shadow p-12">
                <div>
                    <app-back-button
                        href="#"
                        @click.prevent="previousStep()"
                    />
                </div>
                <h2 class="text-center text-pso-dark text-2xl font-bold mb-10">
                    {{ translate('application.quotation_request.title') }}
                </h2>

                <calculation-tool-overview
                    :calculation-tool-id-prop="calculationToolIdProp"
                />

                <div class="mt-10">
                    <p class="text-pso-dark mb-10">
                        {{ translate('application.quotation_request.select_auditor') }}
                    </p>
                    <select-auditors :calculation-tool-id-prop="calculationToolIdProp" />
                </div>

                <div class="action-button text-center mt-10">
                    <button
                        class="inline-block mx-auto py-3 px-8 rounded-xl font-medium text-white bg-blue-1000"
                        :class="selectedAuditors.length > 0 ? 'bg-blue-1000 cursor-pointer' : 'bg-grayish-blue cursor-default'"
                        dusk="go-to-the-next-step"
                        @click.prevent="confirmAddAuditors()"
                    >
                        {{ translate('application.quotation_request.button') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import CalculationToolHeader from "../../../CalculationTool/Parts/CalculationToolHeader/Vue/CalculationToolHeader.vue";
import BackLinkButton from "../../../Templates/Parts/BackLinkButton/Vue/BackLinkButton.vue";
import CalculationTool from "../../../CalculationTool/CalculationTool";
import {mapGetters} from "vuex";
import CalculationToolOverview from "../../../CalculationTool/Parts/CalculationToolOverview/Vue/CalculationToolOverview.vue";
import SelectAuditors from "../../../Auditor/Parts/SelectAuditors/Vue/SelectAuditors.vue";

export default {
    components: {
        SelectAuditors,
        CalculationToolOverview,
        CalculationToolHeader,
        'app-back-button': BackLinkButton,
    },
    props: {
        calculationToolIdProp: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            selectedAuditors: [],
            errors: {},
        }
    },
    computed: {
        ...mapGetters("calculation_tool", {calculationTool: "calculation_tool"}),
    },
    mounted() {
        CalculationTool.get(this.calculationToolIdProp);
        window.mitt.on('actionSelectedAuditors', (actionData) => {
            this.selectedAuditors = actionData;
        });
        window.mitt.on('goToTheNextPage', () => {
            this.goToTheNextPage();
        });
    },
    methods: {
        previousStep() {
            window.location = route('calculation-tool-application.request-selection', {
                calculationTool: this.calculationToolIdProp,
            });
        },
        goToTheNextPage() {
            window.location = route('calculation-tool-application.quotation-request-confirmation', {calculationTool: this.calculationToolIdProp});
        },
        confirmAddAuditors() {
            CalculationTool.validate(this.calculationToolIdProp).then((response) => {
                if (response.message === 'success') {
                    window.mitt.emit("actionAuditorsConfirmation", {});
                }
            }).catch((error) => {
                if (error) {
                    if(422 === error.response.status){
                        this.$setErrorsFromResponse(error.response.data);

                        let dialogData = {
                            body: this.errors.reference_date,
                            buttons: [{
                                text: this.translate('calculation_tool.validate.dialog.button'),
                                redirect: route('calculation-tool-data.reference-date-cao-hours', this.calculationToolIdProp)
                            }]
                        }

                        window.mitt.emit("dialogMessage", dialogData);
                    }
                }
            });
        }
    }
};
</script>
