import {createApp} from 'vue';
import _ from "lodash";
import timelineWidget from "./Vue/TimelineWidget.vue";

export default (selector, options) => {
    let vueOptions = {
        el: selector,
        components: {timelineWidget},
    };

    return createApp(_.merge(options, vueOptions));
}
