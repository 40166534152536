<template>
    <div class="page">
        <div class="container mx-auto">
            <calculation-tool-header />

            <div class="max-w-[1108px] mx-auto bg-white rounded-xl shadow pt-12 pb-12 pl-12 pr-12 px-12">
                <div class="actions-top">
                    <app-back-button
                        href="#"
                        @click.prevent="previousStep()"
                    />
                </div>
                <make-selection
                    v-if="status === statuses.PROSPECTS"
                    :calculation-tool-id-prop="calculationToolIdProp"
                />
                <offer-submited
                    v-if="status === statuses.QUOTATION"
                    :calculation-tool-id-prop="calculationToolIdProp"
                />
                <final-request-applied
                    v-if="status === statuses.FINAL"
                    :calculation-tool-id-prop="calculationToolIdProp"
                />
            </div>
        </div>
    </div>
</template>
<script>

import CalculationToolHeader from "../../../CalculationTool/Parts/CalculationToolHeader/Vue/CalculationToolHeader.vue";
import MakeSelection from "../../Parts/RequestSelection/Vue/MakeSelection.vue";
import OfferSubmited from "../../Parts/RequestSelection/Vue/OfferSubmited.vue";
import CalculationTool from "../../../CalculationTool/CalculationTool";
import {mapGetters} from "vuex";
import BackLinkButton from "../../../Templates/Parts/BackLinkButton/Vue/BackLinkButton.vue";
import FinalRequestApplied from "../../Parts/RequestSelection/Vue/FinalRequestApplied.vue";
import {getStatuses} from "../../../_config/Statuses";

export default {
    components: {FinalRequestApplied, 'app-back-button': BackLinkButton, OfferSubmited, MakeSelection, CalculationToolHeader},
    props: {
        calculationToolIdProp: {
            type: Number,
            required: true,
        }
    },
    data() {
        return {
            status: 1,
            statuses: getStatuses()
        }
    },
    computed: {
        ...mapGetters("calculation_tool", {calculationTool: "calculation_tool"}),
    },
    watch: {
        calculationTool() {
            this.status = this.calculationTool.status;
        }
    },
    mounted() {
        CalculationTool.get(this.calculationToolIdProp);
    },
    methods: {
        previousStep() {
            window.location = route('calculation-tool-application.summary', {
                calculationTool: this.calculationToolIdProp,
            });
        },
    }
};
</script>
