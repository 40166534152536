import Base from "../Base";

import vueRequestSelection from "./RequestSelection";
import vueApplicationIntro from "./ApplicationIntro";
import vueOrganisationSummary from "./OrganisationSummary";
import vueAdditionalInformation from "./AdditionalInformation";
import vueQuotationRequest from "./QuotationRequest";
import vueQuotationRequestSubmit from "./QuotationRequestSubmit";
import vueQuotationRequestConfirmation from "./QuotationRequestConfirmation";
import vueFinalRequestAuditors from './FinalRequestAuditors';
import vueFinalRequest from "./FinalRequest";
import vueFinalRequestInformation from "./FinalRequestInformation";

class Application extends Base {
    static getComponents() {
        return {
            "request-selection": vueRequestSelection,
            "calculation-tool-application": vueApplicationIntro,
            "organisation-summary": vueOrganisationSummary,
            "additional-information": vueAdditionalInformation,
            "quotation-request": vueQuotationRequest,
            "quotation-request-submit": vueQuotationRequestSubmit,
            "quotation-request-confirmation": vueQuotationRequestConfirmation,
            "final-request-auditors": vueFinalRequestAuditors,
            "final-request": vueFinalRequest,
            "final-request-information": vueFinalRequestInformation,
        };
    }
}
export default Application;
