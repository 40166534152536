import { createApp } from 'vue';
import _ from "lodash";
import confirmationDialog from "./Vue/ConfirmationDialog.vue";

export default (selector, options) => {
    let vueOptions = {
        el: selector,
        components: {confirmationDialog},
    }

    return createApp(_.merge(options, vueOptions));

}
