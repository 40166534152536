<template>
    <div>
        <div class="flex justify-between">
            <h3 class="text-pso-dark text-xl font-medium">
                {{ translate('application.quotation_request.subtitle') }}
            </h3>

            <img
                :src="iconChevronUp"
                alt="up"
                class="mr-2 chevron icon hover:cursor-pointer"
                :class="{'rotate-180': !showAll}"
                dusk="chevron-toggle-timeline"
                @click="toggleTimeline()"
            >
        </div>

        <div
            class="mt-6 border-b-2 border-solid border-blue-400"
            :class="{'pb-10': showAll}"
        >
            <div
                class="rounded-lg border border-gray-400 drop-shadow-[0_2px_4px_rgba(6,69,106,0.1)]"
                :class="{'hidden': !showAll}"
            >
                <div class="bg-gray-050 py-6 px-8">
                    <div class="flex align-middle items-center">
                        <h3 class="text-pso-dark text-xl font-medium flex min-w-[240px] pr-4">
                            {{ calculationTool.name }}
                        </h3>
                        <score-bar
                            :percentage-prop="scoringStats.scorePercentage"
                            :show-tooltip-prop="false"
                            :show-bottom-text-prop="false"
                            :border-radius-prop="'rounded'"
                            :small-width-prop="true"
                            class="max-w-[250px]"
                        />
                    </div>
                    <div class="flex justify-between mt-6">
                        <div>
                            <p class="text-gray-800">
                                {{ translate('application.quotation_request.main_organisation') }}
                            </p>
                            <p>{{ organisation.company_name }}</p>
                        </div>
                        <div>
                            <p class="text-gray-800">
                                {{ translate('application.quotation_request.kvk') }}
                            </p>
                            <p>{{ organisation.kvk }}</p>
                        </div>
                        <span
                            v-if="childOrganisations.length > 0"
                            class="border-gray-400 border-r"
                        />
                        <div v-if="childOrganisations.length > 0">
                            <p class="text-gray-800">
                                {{ translate('application.quotation_request.organisation') }}
                            </p>
                            <p
                                v-for="(childOrganisation) in childOrganisations"
                                :key="childOrganisation.kvk"
                            >
                                {{ childOrganisation.company_name }}
                            </p>
                        </div>
                        <div v-if="childOrganisations.length > 0">
                            <p class="text-gray-800">
                                {{ translate('application.quotation_request.kvk') }}
                            </p>
                            <p
                                v-for="(childOrganisation) in childOrganisations"
                                :key="childOrganisation.kvk"
                            >
                                {{ childOrganisation.kvk }}
                            </p>
                        </div>
                    </div>
                </div>
                <div
                    class="pb-4 text-center"
                >
                    <certification-timeline :timeline-dates-prop="timelineDates" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CalculationTool from '../../../CalculationTool';
import {mapGetters} from "vuex";
import _ from "lodash";
import dayjs from 'dayjs';
import CalculationToolData from "../../../../CalculationToolData/CalculationToolData";
import ScoreBar from "../../../../Tools/Parts/ScoreBar/Vue/ScoreBar.vue";
import CertificationTimeline from "../../../../CalculationToolData/Parts/CertificationTimeline/Vue/CertificationTimeline.vue";
import Score from "../../../../Score/Score";

export default {
    components: {
        ScoreBar,
        CertificationTimeline,
    },
    props: {
        calculationToolIdProp: {
            type: Number,
            required: true
        },
        showAllProp: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            scoringStats: {
                scorePercentage: 0,
            },
            organisation: {},
            childOrganisations: {},
            showAll: true,
            timelineDates: {
                start: null,
                reference: null,
                today: null,
                certificateStart: null,
                certificateEnd: null,
            },
            iconChevronUp: new URL('/resources/images/icon_chevron_up.svg', import.meta.url).href,
        };
    },
    computed: {
        ...mapGetters('calculation_tool', {calculationTool: 'calculation_tool'}),
        ...mapGetters('calculation_tool', {receivedOrganisationsList: 'organisations_list'}),
        ...mapGetters('calculation_tool_data', {calculationToolData: 'calculation_tool_data'})
    },
    watch: {
        receivedOrganisationsList() {
            const mainOrganisation = _.first(_.filter(this.receivedOrganisationsList, function (organisation) {
                return organisation.data.is_main;
            }));

            const childOrganisations = _.filter(this.receivedOrganisationsList, function (organisation) {
                return !organisation.data.is_main;
            });
            this.organisation = mainOrganisation ? mainOrganisation.data : null;
            this.childOrganisations = childOrganisations.map(org => org.data);
        },
        calculationToolData() {
            const referenceDate = dayjs(this.calculationToolData.reference_date);
            const todayDate = dayjs();

            this.timelineDates = {
                start: referenceDate.subtract(1, 'year').format('DD-MM-YYYY'),
                reference: referenceDate.format('DD-MM-YYYY'),
                today: todayDate.format('DD-MM-YYYY'),
                certificateStart: todayDate.startOf('month').add(1, 'month').format('DD-MM-YYYY'),
                certificateEnd: todayDate.startOf('month').add(1, 'month').add(2, 'year').format('DD-MM-YYYY'),
            };
        },
    },
    mounted() {
        CalculationTool.getOrganisations(this.calculationToolIdProp);
        CalculationToolData.get(this.calculationToolIdProp);
        Score.getNorms(this.calculationToolIdProp);
        Score.getScoringStats(this.calculationToolIdProp)
            .then((response) => {
                this.scoringStats = response;
            });

        this.showAll = this.showAllProp;
    },
    methods: {
        toggleTimeline() {
            this.showAll = !this.showAll;
        },
    },
};
</script>
