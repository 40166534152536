import {createApp} from 'vue';
import _ from "lodash";
import welcomeAuditorPage from "./Vue/WelcomeAuditorPage.vue";

export default (selector, options) => {
    let vueOptions = {
        el: selector,
        components: {welcomeAuditorPage},
    }

    return createApp(_.merge(options, vueOptions));
}
