import { createApp } from 'vue';
import _ from "lodash";
import baseDatePickerInput from "./Vue/BaseDatePickerInput.vue";

export default (selector, options) => {
    let vueOptions = {
        el: selector,
        components: {baseDatePickerInput},
    }

    return createApp(_.merge(options, vueOptions));
}
