import { createApp } from 'vue';
import _ from "lodash";
import dialogMessage from "./Vue/DialogMessage.vue";

export default (selector, options) => {
    let vueOptions = {
        el: selector,
        components: {dialogMessage},
    }

    return createApp(_.merge(options, vueOptions));
}
