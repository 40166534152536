<template>
    <div class="page">
        <div class="container mx-auto">
            <calculation-tool-header />
            <div class="max-w-[1108px] mx-auto bg-white rounded-xl shadow p-12">
                <div>
                    <app-back-button
                        href="#"
                        @click.prevent="previousStep()"
                    />
                </div>
                <h2 class="text-center text-pso-dark text-2xl font-bold mb-10">
                    {{ translate('application.additional_information.title') }}
                </h2>
                <p
                    class="text-pso-dark mt-10"
                >
                    {{ translate('application.additional_information.sub_title') }}
                </p>
                <hr class="border-blue-400 mt-10">
                <contact-details
                    :calculation-tool-id-prop="calculationToolIdProp"
                />
                <hr class="border-blue-400 mt-10">
                <billing-information
                    :calculation-tool-id-prop="calculationToolIdProp"
                />
                <hr class="border-blue-400 mt-10">
                <other-information
                    :calculation-tool-id-prop="calculationToolIdProp"
                />
                <div class="text-center mt-14">
                    <button
                        class="inline-block mx-auto py-3 px-8 rounded-xl font-medium text-white bg-pso-orange"
                        dusk="save_button"
                        @click.prevent="saveAndContinue()"
                    >
                        {{ translate('application.additional_information.save_button') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import BillingInformation from '../../Parts/BillingInformation/Vue/BillingInformation.vue';
import OtherInformation from '../../Parts/OtherInformation/Vue/OtherInformation.vue';
import ContactDetails from '../../Parts/ContactDetails/Vue/ContactDetails.vue';
import CalculationToolHeader from "../../../CalculationTool/Parts/CalculationToolHeader/Vue/CalculationToolHeader.vue";
import BackLinkButton from "../../../Templates/Parts/BackLinkButton/Vue/BackLinkButton.vue";
import CalculationTool from "../../../CalculationTool/CalculationTool";
import {mapGetters} from "vuex";
import {getSteps} from "../../../_config/Steps";

export default {
    components: {
        BillingInformation,
        OtherInformation,
        ContactDetails,
        CalculationToolHeader,
        'app-back-button': BackLinkButton,
    },
    props: {
        calculationToolIdProp: {
            type: String,
            default: ''
        }
    },
    computed: {
        ...mapGetters("calculation_tool", {receivedOrganisationsList: "organisations_list"}),
    },
    data() {
        return {
            successActions: 0
        }
    },
    mounted() {
        CalculationTool.get(this.calculationToolIdProp);
        CalculationTool.getOrganisations(this.calculationToolIdProp);

        window.mitt.on('continueToNextStepFromStep6', () => {
            this.successActions++;

            // if all subcomponents saved data successfully
            // this.receivedOrganisationsList.length + 2 is total number of subcomponents that we have on this page
            if(this.successActions === this.receivedOrganisationsList.length + 2){
                CalculationTool.updateStep({calculationTool: this.calculationToolIdProp, step: getSteps().application_summary})
                    .then((response) => {
                        if (response.status === 200) {
                            window.location = route('calculation-tool-application.summary', {
                                calculationTool: this.calculationToolIdProp,
                            });
                        }
                    }).catch((error) => {
                        console.log(error.response.data.data.message);
                    });


            }
        });
    },
    methods: {
        previousStep() {
            window.location = route('calculation-tool-application.index', {
                calculationTool: this.calculationToolIdProp,
            });
        },
        saveAndContinue() {
            this.successActions = 0;
            window.mitt.emit("saveOrganisationContact", {});
            window.mitt.emit("saveInvoiceDetails", {});
            window.mitt.emit("saveAdditionalInformation", {});
        }
    }
};
</script>
