<template>
    <div class="page">
        <div class="container mx-auto">
            <calculation-tool-header />
            <div class="max-w-[1108px] mx-auto bg-white rounded-xl p-12">
                <div>
                    <app-back-button
                        href="#"
                        @click.prevent="previousStep()"
                    />
                </div>
                <h2 class="text-center text-pso-dark text-2xl font-bold mb-10">
                    {{ translate('application.final_request_auditors.title') }}
                </h2>
                <div>
                    <calculation-tool-overview
                        :calculation-tool-id-prop="calculationToolIdProp"
                    />
                    <div class="my-8">
                        <p class="mb-6 font-bold">
                            {{ translate('application.final_request_auditors.paragraph_1_title') }}
                        </p>
                        <p class="text-pso-dark">
                            {{ translate('application.final_request_auditors.paragraph_1_description') }}
                        </p>
                        <ul class="list-disc pl-5">
                            <li class="text-pso-dark">
                                {{ translate('application.final_request_auditors.paragraph_1_bullet_point_1') }}
                            </li>
                            <li class="text-pso-dark">
                                {{ translate('application.final_request_auditors.paragraph_1_bullet_point_2') }}
                            </li>
                            <li class="text-pso-dark">
                                {{ translate('application.final_request_auditors.paragraph_1_bullet_point_3') }}
                            </li>
                            <li class="text-pso-dark">
                                {{ translate('application.final_request_auditors.paragraph_1_bullet_point_4') }}
                            </li>
                            <li class="text-pso-dark">
                                {{ translate('application.final_request_auditors.paragraph_1_bullet_point_5_part_1') }}
                                <a
                                    :href="links.apply_for_pso_quality_mark"
                                    target="_blank"
                                    class="text-pso-primary underline"
                                >
                                    {{ translate('application.final_request_auditors.paragraph_1_bullet_point_5_link') }}
                                </a>
                                {{ translate('application.final_request_auditors.paragraph_1_bullet_point_5_part_2') }}
                            </li>
                        </ul>
                    </div>
                    <div
                        class="pt-8 border-t-2 mb-8 border-solid border-blue-400"
                    >
                        <p class="mb-6 font-bold">
                            {{ translate('application.final_request_auditors.paragraph_2_title') }}
                        </p>
                        <p class="mb-6 text-pso-dark">
                            {{ translate('application.final_request_auditors.paragraph_2_part_1') }}
                        </p>
                        <p class="text-pso-dark">
                            {{ translate('application.final_request_auditors.paragraph_2_part_2') }}
                            <a
                                :href="links.pso_avg"
                                target="_blank"
                                class="text-pso-primary underline"
                            >
                                {{ translate('application.final_request_auditors.paragraph_2_part_2_link') }}
                            </a>
                        </p>
                    </div>
                    <div class="mx-auto bg-white rounded-xl">
                        <p class="mb-10">
                            {{ translate('application.final_request_auditors.select_auditor') }}
                        </p>
                        <select-auditor
                            :calculation-tool-id-prop="calculationToolIdProp"
                        />
                        <div>
                            <p class="mb-2"> {{ translate('application.final_request_auditors.audit_date') }}</p>
                            <DatePickerInput
                                id="customer_audit_date"
                                v-model:model-value="calculationTool.customer_audit_date"
                                name="customer_audit_date"
                                :placeholder="translate('calculation_tool_data.target_group_data.date_in_service_placeholder')"
                                dusk="modal-edit-tge-btn-datepicker-date-in-service"
                                modelType="yyyy-MM-dd"
                            />
                    </div>

                        <div class="action-button text-center">
                            <button
                                class="inline-block mx-auto py-3 mt-8 px-8 rounded-xl font-medium text-white bg-pso-orange"
                                :class="hasAuditorSelected() ? '' : 'opacity-50 cursor-not-allowed'"
                                :disabled="!hasAuditorSelected()"
                                dusk="go-to-the-next-step"
                                @click.prevent="addAuditor()"
                            >
                                {{ translate('application.final_request_auditors.button') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import SelectAuditor from "../../../Auditor/Parts/SelectAuditor/Vue/SelectAuditor.vue";
import CalculationToolHeader from "../../../CalculationTool/Parts/CalculationToolHeader/Vue/CalculationToolHeader.vue";
import BackLinkButton from "../../../Templates/Parts/BackLinkButton/Vue/BackLinkButton.vue";
import CalculationTool from "../../../CalculationTool/CalculationTool";
import CalculationToolOverview from "../../../CalculationTool/Parts/CalculationToolOverview/Vue/CalculationToolOverview.vue";
import {mapGetters} from "vuex";
import Links from "../../../_config/Links.js"
import DatePickerInput from "../../../FormInput/DatePickerInput/Vue/DatePickerInput.vue";
import dayjs from "dayjs";

export default {
    components: {
        CalculationToolOverview,
        CalculationToolHeader,
        SelectAuditor,
        'app-back-button': BackLinkButton,
        DatePickerInput
    },
    props: {
        calculationToolIdProp: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            links: Links,
            selectedAuditors: null,
            showAll: true,
            iconChevronUp: new URL('/resources/images/icon_chevron_up.svg', import.meta.url).href,
        };
    },
    computed: {
        ...mapGetters('calculation_tool', {calculationTool: 'calculation_tool'}),
    },
    mounted() {
        CalculationTool.get(this.calculationToolIdProp);

        window.mitt.on('actionSelectedAuditors', (actionData) => {
            this.selectedAuditors = actionData;
        });

        window.mitt.on('goToTheNextPage', () => {
            this.goToTheNextPage();
        });
    },
    methods: {
        hasAuditorSelected() {
            return this.selectedAuditors ?? null;
        },
        addAuditor() {
            window.mitt.emit("actionAddAuditor", {});
        },
        toggleText() {
            this.showAll = !this.showAll;
        },
        goToTheNextPage() {
            if(this.calculationTool.customer_audit_date) {
                let formData = {
                    id: this.calculationToolIdProp,
                    customer_audit_date: this.calculationTool.customer_audit_date
                }
                CalculationTool.updateCalculationToolForm(formData)
                    .catch((error) => {
                        if (error) {
                            this.errorMessage = error.response ? error.response.data.message : error.message;
                        }
                    });
            }
            
            window.location = route('calculation-tool-application.final-request', {calculationTool: this.calculationToolIdProp});
        },
        previousStep() {
            window.location = route('calculation-tool-application.final-request-information', {calculationTool: this.calculationToolIdProp});
        }
    }
};
</script>
