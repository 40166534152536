<template>
    <div class="page wizard-page">
        <div class="container mx-auto py-8">
            <div class="w-3/4 lg:w-1/2 xl:w-1/3 mx-auto bg-white rounded-xl shadow p-8 pt-6">
                <div class="actions-top">
                    <app-back-button :href="backRoute()" />
                    <div class="bg-gray-200 rounded-full h-1.5 dark:bg-gray-700 mt-4">
                        <div
                            class="bg-pso-primary h-1.5 rounded-full"
                            :style="progressWidth"
                        />
                    </div>
                </div>
                <div
                    class="wizard-body mt-10 mb-10 text-center"
                >
                    <p class="text-pso-dark text-lg">
                        {{ translate(wizardData.message) }}
                    </p>
                    <p
                        v-if="wizardData.message_explanation"
                        class="text-md text-gray-800 mt-4"
                    >
                        {{ translate(wizardData.message_explanation) }}
                    </p>
                    <div
                        v-if="!wizardData.hideOptions"
                        class="flex justify-center mt-10"
                    >
                        <div
                            class="radio-button-wrapper flex flex-row-reverse justify-end items-center border rounded-xl mr-4"
                            :class="confirmation === '1' ? 'border-blue-800 bg-blue-200' : 'border-gray-200'"
                        >
                            <label
                                for="confirmationYes"
                                class="text-md text-black items-center mb-0 pl-2 pt-3 pb-3 pl-3 w-1/3 pr-20 hover:cursor-pointer"
                            >{{ translate('layout.wizard.yes') }}</label>
                            <input
                                id="confirmationYes"
                                v-model="confirmation"
                                type="radio"
                                class="form-radio appearance-none rounded-full h-4 w-4 border border-gray-200 bg-white text-blue-800 checked:text-pso-primary focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 ml-4 cursor-pointer "
                                name="confirmation"
                                value="1"
                                @change="confirmationChange()"
                            >
                        </div>

                        <div
                            class="radio-button-wrapper flex flex-row-reverse justify-end items-center border rounded-xl"
                            :class="confirmation === '0' ? 'border-blue-800 bg-blue-200' : 'border-gray-200'"
                        >
                            <label
                                for="confirmationNo"
                                class="text-md text-black items-center mb-0 pl-2 pt-3 pb-3 pl-3 w-1/3 pr-20 hover:cursor-pointer"
                            >
                                {{ translate('layout.wizard.no') }}
                            </label>
                            <input
                                id="confirmationNo"
                                v-model="confirmation"
                                type="radio"
                                class="form-radio appearance-none rounded-full h-4 w-4 border border-gray-200 bg-white text-blue-800 checked:text-pso-primary focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 ml-4 cursor-pointer"
                                name="confirmation"
                                value="0"
                                @change="confirmationChange()"
                            >
                        </div>
                    </div>
                </div>
                <div class="actions-bottom">
                    <div class="action-button text-center">
                        <a
                            :href="confirmation ? redirect : 'javascript:void(0)'"
                            class="inline-block mx-auto py-3 px-8 rounded-xl font-medium text-white bg-pso-orange"
                            :class="confirmation ? '' : 'opacity-50 cursor-not-allowed'"
                            dusk="wizard-next-btn"
                        >
                            {{ translate(wizardData.buttonText) }}
                        </a>
                    </div>
                </div>
                <div
                    v-if="wizardData.secondButtonText"
                    class="actions-bottom mt-4"
                >
                    <div class="action-button text-center">
                        <a
                            :href="confirmation ? redirectSecond : 'javascript:void(0)'"
                            class="inline-block mx-auto py-3 px-8 rounded-xl font-medium text-white bg-pso-secondary"
                            :class="confirmation ? '' : ''"
                            dusk="wizard-second-btn"
                        >
                            {{ translate(wizardData.secondButtonText) }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import User from "../../../../User/User";
import BackLinkButton from "../../../../Templates/Parts/BackLinkButton/Vue/BackLinkButton.vue";

export default {
    components: {'app-back-button': BackLinkButton},
    props: {
        wizardData: {
            type: Object,
            default() {
                return {
                    message: '',
                    buttonText: 'next',
                    progressBarPercentage: '0',
                    redirectYes: '',
                    redirectNo: '',
                    hideOptions: false,
                    secondButtonText: '',
                    backRoute: '/'
                };
            }
        }
    },
    data() {
        return {
            progressWidth: '',
            confirmation: '',
            redirect: '',
            redirectSecond: ''
        };
    },
    created() {
        if (this.wizardData.secondButtonText) {
            this.confirmation = 1;
            this.redirect = this.wizardData.redirectNo;
            this.redirectSecond = this.wizardData.redirectYes;
        }
        this.progressWidth = "width: " + this.wizardData.progressBarPercentage + "%";
    },
    methods: {
        confirmationChange() {
            if (this.confirmation === "1") {
                this.redirect = this.wizardData.redirectYes;
            } else {
                this.redirect = this.wizardData.redirectNo;
            }
        },
        backRoute() {
            return this.wizardData.backRoute;
        }
    }
};
</script>
