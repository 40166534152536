<template>
    <div class="mt-10">
        <h2 class="text-center text-pso-dark text-xl font-bold">
            {{ translate('application.additional_information.other.title') }}
        </h2>
        <div class="mt-10">
            <div>
                {{ translate('application.additional_information.other.email') }}
            </div>
            <div
                class="w-7/12 mt-2"
            >
                <input
                    id="communications_email"
                    v-model="form.communications_email"
                    :class="{'border-red-500': errors.communications_email}"
                    :placeholder="translate('application.additional_information.other.email')"
                    autocomplete=""
                    class="form-input px-6 py-3 rounded-xl w-full border-gray-200 text-pso-dark text-lg placeholder:text-gray-600"
                    name="communications_email"
                    type="text"
                >
                <span
                    v-if="errors.communications_email"
                    class="text-sm text-red-500"
                    role="alert"
                    v-text="errors.communications_email"
                />
            </div>
            <div class="mt-6">
                {{ translate('application.additional_information.other.second_contact') }}
            </div>
            <div
                class="w-7/12 mt-2"
            >
                <input
                    id="invitations_email"
                    v-model="form.invitations_email"
                    :class="{'border-red-500': errors.invitations_email}"
                    :placeholder="translate('application.additional_information.other.second_contact')"
                    autocomplete=""
                    class="form-input px-6 py-3 rounded-xl w-full border-gray-200 text-pso-dark text-lg placeholder:text-gray-600"
                    name="invitations_email"
                    type="text"
                >
                <span
                    v-if="errors.invitations_email"
                    class="text-sm text-red-500"
                    role="alert"
                    v-text="errors.invitations_email"
                />
            </div>
            <div class="mt-6">
                {{ translate('application.additional_information.other.advisor') }}
            </div>
            <div
                class="w-7/12 mt-2"
            >
                <Multiselect
                    v-model="form.advisor_id"
                    :can-clear="false"
                    :searchable="true"
                    :classes="{
                        caret: 'icon_chevron_down bg-no-repeat w-[22px] h-4 z-10 transition-transform transform pointer-events-none rtl:mr-0 rtl:ml-3.5',
                        placeholder: 'flex items-center h-full absolute left-2.5 italic font-[system-ui] top-0 pointer-events-none bg-transparent leading-snug pl-3.5 text-[#AEB4B8] rtl:left-auto rtl:right-0 rtl:pl-0 rtl:pr-3.5',
                    }"
                    :options="advisorsOptions"
                    :placeholder="translate('application.additional_information.other.advisor')"
                    dusk="select-advisors-dropdown"
                />
                <span
                    v-if="errors.advisor_id"
                    class="text-sm text-red-500"
                    role="alert"
                    v-text="errors.advisor_id"
                />
            </div>
        </div>
    </div>
</template>

<script>

import Multiselect from '@vueform/multiselect';
import {mapGetters} from "vuex";
import Advisor from '../../../../Advisor/Advisor';
import CalculationTool from "../../../../CalculationTool/CalculationTool";

export default {
    components: {
        Multiselect,
    },
    props: {
        calculationToolIdProp: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            form: {
                advisor_id: null,
            },
            errors: {},
        };
    },
    computed: {
        ...mapGetters('advisor', {advisorsOptions: 'advisors'}),
        ...mapGetters('calculation_tool', {additionalInformation: 'additional_information'}),
    },
    watch: {
        additionalInformation() {
            this.form.invitations_email = this.additionalInformation ? this.additionalInformation.invitations_email : '';
            this.form.communications_email = this.additionalInformation ? this.additionalInformation.communications_email : '';
            this.form.advisor_id = this.additionalInformation ? this.additionalInformation.advisor_id : '';
        },
    },
    mounted() {
        Advisor.index();
        CalculationTool.getAdditionalInformation(this.calculationToolIdProp);

        window.mitt.on('saveAdditionalInformation', () => {
            let formData = CalculationTool.buildAdditionalInformationForm(this.form);
            CalculationTool.updateAdditionalInformation(this.calculationToolIdProp, formData)
                .then((data) => {
                    this.errors = {};
                    window.mitt.emit("continueToNextStepFromStep6", {});
                })
                .catch((error) => {
                    if (error) {
                        if ([401, 404].includes(error.response.status)) {
                            this.failed = error.response.data.message;
                        }
                        if(422 === error.response.status){
                            this.$setErrorsFromResponse(error.response.data);
                        }
                    }
                });
        });
    }
};
</script>
