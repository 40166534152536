<template>
    <div class="border border-blue-400 rounded-lg p-8 mt-10">
        <h2 class="text-center text-lg font-medium text-pso-dark">
            {{ elementTitle + ': ' + organisation.company_name }}
        </h2>
        <div class="flex">
            <div class="w-2/3">
                <div class="mt-6">
                    {{ translate('application.additional_information.contact.phone') }}
                </div>
                <div
                    class="mt-2 mr-6"
                >
                    <input
                        id="phone"
                        v-model="form.phone_number"
                        :class="{'border-red-500': errors.phone_number}"
                        :placeholder="translate('application.additional_information.contact.phone_placeholder')"
                        autocomplete=""
                        class="form-input px-6 py-3 rounded-xl w-full border-gray-200 text-pso-dark text-lg placeholder:text-gray-600"
                        :name="'organisation_phone[' + organisation.id + ']'"
                        type="text"
                    >
                    <span
                        v-if="errors.phone_number"
                        class="text-sm text-red-500"
                        role="alert"
                        v-text="errors.phone_number"
                    />
                </div>
                <div class="mt-6">
                    {{ translate('application.additional_information.contact.email') }}
                </div>
                <div class="mt-2 mr-6">
                    <input
                        id="email"
                        v-model="form.email"
                        :class="{'border-red-500': errors.email}"
                        :placeholder="translate('application.additional_information.contact.email_placeholder')"
                        autocomplete=""
                        class="form-input px-6 py-3 rounded-xl w-full border-gray-200 text-pso-dark text-lg placeholder:text-gray-600"
                        :name="'organisation_email[' + organisation.id + ']'"
                        type="text"
                    >
                    <span
                        v-if="errors.email"
                        class="text-sm text-red-500"
                        role="alert"
                        v-text="errors.email"
                    />
                </div>
                <div class="mt-6">
                    {{ translate('application.additional_information.contact.website') }}
                </div>
                <div
                    class="mt-2 mr-6"
                >
                    <input
                        id="website"
                        v-model="form.website"
                        :class="{'border-red-500': errors.website}"
                        :placeholder="translate('application.additional_information.contact.website_placeholder')"
                        autocomplete=""
                        class="form-input px-6 py-3 rounded-xl w-full border-gray-200 text-pso-dark text-lg placeholder:text-gray-600"
                        :name="'organisation_website[' + organisation.id + ']'"
                        type="text"
                        @change="checkWebsitePrefix()"
                    >
                    <span
                        v-if="errors.website"
                        class="text-sm text-red-500"
                        role="alert"
                        v-text="errors.website"
                    />
                </div>
            </div>
            <div class="w-1/3 pt-6">
                <button
                    v-if="organisation.logo"
                    class="pl-6"
                    @click.prevent="openUploadFileDialog()"
                >
                    <img
                        :alt="organisation.company_name"
                        :src="organisation.logo_base64"
                        class="w-full m-0 w-[100px]"
                    >
                    <span class="text-pso-secondary text-md underline">
                        {{ translate('application.additional_information.contact.upload_logo') }}
                    </span>
                </button>
                <button
                    v-if="!organisation.logo"
                    :class="{'border-red-500 border': errors.logo}"
                    class="flex justify-center flex-col w-full h-full bg-gray-200 text-center text-pso-dark rounded-xl drop-zone"
                    @click.prevent="openUploadFileDialog()"
                >
                    <span class="block pt-6 text-center w-full">
                        {{ translate('application.additional_information.contact.upload_logo') }}
                    </span>
                    <img
                        :src="iconUploadRound"
                        alt="upload icon"
                        class="block max-w-full mt-12 m-auto pt-4 pb-6 pl-6 pr-6 cursor-pointer"
                    >
                </button>
                <span
                    v-if="errors.logo"
                    class="text-sm text-red-500"
                    role="alert"
                    v-text="errors.logo"
                />

                <input
                    ref="logoUploadInput"
                    class="hidden"
                    name="logoUploadInput"
                    type="file"
                    @change="uploadLogoImage($event)"
                >
            </div>
        </div>
    </div>
</template>

<script>
import Organisation from '@Organisation/Organisation';

export default {
    props: {
        calculationToolIdProp: {
            type: String,
            default: ''
        },
        organisationProp: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            organisation: {
                id: null,
                company_name: '',
                email: '',
                phone_number: '',
                website: '',
            },
            form: {},
            errors: {},
            failed: null,
            iconUploadRound: new URL('/resources/images/icon_upload_circled.svg', import.meta.url).href,
            elementTitle: '',
        };
    },
    created() {
        this.organisation = this.organisationProp;
        this.form.phone_number = this.organisation.phone_number;
        this.form.email = this.organisation.email;
        this.form.website = this.organisation.website ?? '';
        this.elementTitle = this.organisation.is_main === true
            ? this.translate('application.additional_information.contact.main_applicant')
            : this.translate('application.additional_information.contact.entity');

        window.mitt.on('saveOrganisationContact', () => {
            let formData = Organisation.buildUpdateOrganisationForm(this.form);
            Organisation.updateOrganisationContact(this.organisation.id, formData)
                .then((data) => {
                    this.errors = {};
                    if(this.organisation.logo) {
                        window.mitt.emit("continueToNextStepFromStep6", {});
                    } else {
                        this.errors.logo = this.translate('application.additional_information.contact.logo_required');
                    }
                })
                .catch((error) => {
                    if (error) {
                        if ([401, 404].includes(error.response.status)) {
                            this.failed = error.response.data.message;
                        }
                        if(422 === error.response.status){
                            this.$setErrorsFromResponse(error.response.data);
                            if(!this.organisation.logo) {
                                this.errors.logo = this.translate('application.additional_information.contact.logo_required');
                            }
                        }
                    }
                });
        });
    },

    methods: {
        openUploadFileDialog() {
            this.$refs.logoUploadInput.click();
        },

        uploadLogoImage($event) {
            let formData = new FormData();
            formData.append("logo", $event.target.files[0]);
            formData.append("_method", 'PATCH');

            Organisation.updateOrganisationLogo(this.organisation.id, formData)
                .then((data) => {
                    this.organisation.logo_base64 = data.data.logo_base64;
                    this.organisation.logo = true;
                })
                .catch((error) => {
                    if (error) {
                        this.errors = error.response.data.message;
                    }
                });
        },
        checkWebsitePrefix() {
            if(this.form.website && !this.form.website.startsWith('http') && !this.form.website.startsWith('https')){
                this.form.website = 'https://' + this.form.website;
            }
        }
    }
};
</script>
