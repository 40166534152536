import {createApp} from 'vue';
import _ from "lodash";
import organisationSummary from "./Vue/OrganisationSummary.vue";

export default (selector, options) => {
    let vueOptions = {
        el: selector,
        components: {organisationSummary: organisationSummary},
    };

    return createApp(_.merge(options, vueOptions));
}
