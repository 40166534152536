import Base from "../Base";
import vueWelcome from "./Parts/Welcome";
import vueWelcomeAuditor from "./Parts/WelcomeAuditor";
import vueCustomerDashboard from "./Parts/CustomerDashboard";
import vueAuditorDashboard from "./Parts/AuditorDashboard";
import vuePsoDashboard from "./Parts/PsoDashboard";

class Dashboard extends Base {
    static getComponents() {
        return {
            "welcome-page": vueWelcome,
            "welcome-auditor-page": vueWelcomeAuditor,
            "customer-dashboard": vueCustomerDashboard,
            "auditor-dashboard": vueAuditorDashboard,
            "pso-dashboard": vuePsoDashboard,
        };
    }
}

export default Dashboard;
