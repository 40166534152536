import { createApp } from 'vue';
import _ from "lodash";
import multiSelectWithAuditor from "./Vue/MultiSelectWithAuditor.vue";

export default (selector, options) => {
    let vueOptions = {
        el: selector,
        components: {multiSelectWithAuditor},
    }

    return createApp(_.merge(options, vueOptions));
}
