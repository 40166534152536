import _ from "lodash";
import { createApp } from 'vue/dist/vue.esm-bundler'

import ToasterMessage from "./Vue/ToasterMessage.vue";
export default (selector, options) => {
    let vueOptions = {
        el: selector,
        components: {ToasterMessage},
    }
    return createApp(_.merge(options, vueOptions));
}
