import Base from "../Base";
import vueBaseInput from "./BaseInput";
import vueBaseMultiSelectInput from "./BaseMultiSelectInput";
import vueBaseDatePickerInput from "./BaseDatePickerInput";
import vueTextInput from "./TextInput";
import vueNumberInput from "./NumberInput";
import vueMultiSelectInput from "./MultiSelectInput";
import vueDatePickerInput from "./DatePickerInput";
import vueAuditorComment from "./Auditor/AuditorComment";
import vueDatePickerInputWithAuditor from "./Auditor/DatePickerInputWithAuditor";
import vueMultiSelectInputWithAuditor from "./Auditor/MultiSelectWithAuditor";
import vueNumberInputWithAuditor from "./Auditor/NumberInputWithAuditor";
import vueTextInputWithAuditor from "./Auditor/TextInputWithAuditor";


class FormInput extends Base {
    static getComponents() {
        return {
            "base-input": vueBaseInput,
            "base-multi-select-input": vueBaseMultiSelectInput,
            "base-date-picker-input": vueBaseDatePickerInput,
            "text-input": vueTextInput,
            "number-input": vueNumberInput,
            "multi-select-input": vueMultiSelectInput,
            "date-picker-input": vueDatePickerInput,

            "auditor-comment": AuditorComment,
            "date-picker-input-with-auditor": vueDatePickerInputWithAuditor,
            "multi-select-input-with-auditor": vueMultiSelectInputWithAuditor,
            "number-input-with-auditor": vueNumberInputWithAuditor,
            "text-input-with-auditor": vueTextInputWithAuditor,

        };
    }
}

export default FormInput;
