<template>
    <auditor-select-box
        :calculation-tool-id-prop="calculationToolIdProp"
        :is-single-auditor-prop="true"
    />
</template>

<script>
import CalculationTool from '../../../../CalculationTool/CalculationTool';
import AuditorSelectBox from "../../AuditorSelectBox/Vue/AuditorSelectBox.vue";

export default {
    components: {AuditorSelectBox},
    props: {
        calculationToolIdProp: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            selectedAuditor: '',
        };
    },
    watch: {
        selectedAuditor() {
            window.mitt.emit("actionSelectedAuditors", this.selectedAuditor);
        }
    },
    mounted() {
        CalculationTool.get(this.calculationToolIdProp);

        window.mitt.on('actionGetSelectedAuditors', (actionData) => {
            this.selectedAuditor = actionData;
        });

        window.mitt.on('actionAddAuditor', () => {
            CalculationTool.addAuditorCompany(this.calculationToolIdProp, {id: this.selectedAuditor}).then(() => {
                window.mitt.emit("goToTheNextPage", {});
            });
        });
    },
};
</script>
