<template>
    <div class="page">
        <div class="container mx-auto">
            <calculation-tool-header />
            <div class="max-w-[1108px] mx-auto bg-white rounded-xl shadow pt-12 pb-12 pl-12 pr-12 px-12">
                <h2 class="text-center text-pso-dark text-2xl font-bold mb-10">
                    {{ translate('application.intro.title') }}
                </h2>
                <p
                    class="text-pso-dark mb-10"
                >
                    {{ translate('application.intro.paragraph') }}
                </p>
                <ul class="text-pso-dark list-disc mb-10 ml-5">
                    <li class="text-pso-dark">
                        {{ translate('application.intro.list_1') }}
                    </li>
                    <li class="text-pso-dark">
                        {{ translate('application.intro.list_2') }}
                    </li>
                    <li class="text-pso-dark">
                        {{ translate('application.intro.list_3') }}
                    </li>
                </ul>

                <div class="action-button text-center mb-10">
                    <button
                        class="inline-block mx-auto py-3 px-8 rounded-xl font-medium text-white bg-pso-orange"
                        @click.prevent="nextPageRoute()"
                    >
                        {{ translate('application.intro.button') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import CalculationToolHeader from "../../../CalculationTool/Parts/CalculationToolHeader/Vue/CalculationToolHeader.vue";
import CalculationTool from "../../../CalculationTool/CalculationTool";
import {getSteps} from "../../../_config/Steps"

export default {
    components: {CalculationToolHeader},
    props: {
        calculationToolIdProp: {
            type: Number,
            required: true,
        }
    },
    mounted() {
        CalculationTool.get(this.calculationToolIdProp);
    },
    methods: {
        nextPageRoute() {
            CalculationTool.updateStep({calculationTool: this.calculationToolIdProp, step: getSteps().additional_information})
                .then((response) => {
                    if (response.status === 200) {
                        window.location = route('calculation-tool-application.additional-information', {calculationTool: this.calculationToolIdProp});
                    }
                }).catch((error) => {
                    console.log(error.response.data.data.message);
                });
        }
    }
};
</script>

