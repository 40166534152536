<template>
    <div class="page">
        <div class="container mx-auto">
            <calculation-tool-header />
            <div class="max-w-[1108px] mx-auto bg-white rounded-xl shadow pt-12 pb-12 pl-12 pr-12 px-12">
                <h2 class="text-center text-pso-dark text-2xl font-bold mb-10">
                    {{ translate('application.offer_request_confirmation.title') }}
                </h2>
                <p class="text-pso-dark mb-10">
                    {{ translate('application.offer_request_confirmation.paragraph_one') }}
                </p>
                <ul class="text-pso-dark list-disc mb-10 ml-5">
                    <li
                        v-for="(auditor) in selectedAuditors"
                        :key="auditor.id"
                        class="text-pso-dark"
                    >
                        {{ auditor.name }}
                    </li>
                </ul>

                <p class="text-pso-dark mb-10">
                    {{ translate('application.offer_request_confirmation.paragraph_two') }}
                </p>

                <div class="action-button text-center">
                    <button
                        class="inline-block mx-auto py-3 px-8 rounded-xl font-medium text-white bg-blue-1000"
                        dusk="next-page-button"
                        @click.prevent="goToNextPage()"
                    >
                        {{ translate('application.offer_request_confirmation.button') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import CalculationToolHeader from "../../../CalculationTool/Parts/CalculationToolHeader/Vue/CalculationToolHeader.vue";
import CalculationTool from "../../../CalculationTool/CalculationTool";
import {mapGetters} from 'vuex';
import {getSteps} from "../../../_config/Steps"

export default {
    components: {CalculationToolHeader},
    props: {
        calculationToolIdProp: {
            type: String,
            default: ''
        },
    },
    data() {
        return {};
    },
    computed: {
        ...mapGetters('calculation_tool', {selectedAuditors: 'requested_auditors_list'}),
    },
    mounted() {
        CalculationTool.get(this.calculationToolIdProp);
        CalculationTool.getRequestedAuditors(this.calculationToolIdProp);
    },
    methods: {
        goToNextPage() {
            CalculationTool.updateStep({calculationTool: this.calculationToolIdProp, step: getSteps().final_application})
                .catch((error) => {
                    console.log(error.response.data.data.message);
                });

            window.location = route('calculation-tool-application.quotation-request-submit', {
                calculationTool: this.calculationToolIdProp,
            });
        }
    }
};
</script>
