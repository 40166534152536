<template>
    <div class="page organisation-summary">
        <div class="container mx-auto">
            <calculation-tool-header />
            <div class="max-w-[1108px] mx-auto bg-white rounded-xl shadow pt-12 pb-12 pl-12 pr-12 px-12">
                <app-back-button
                    href="#"
                    @click.prevent="previousStep()"
                />

                <h1 class="text-center text-pso-dark text-2xl font-bold mb-10">
                    {{ translate('organisation.summary.page_title') }}
                </h1>
                <ul class="organisations-list mb-10">
                    <li
                        v-for="(organisation, index) in organisationsList"
                        :key="organisation.data.id"
                        class="flex justify-end mb-8"
                    >
                        <organisation-in-summary
                            :organisation-prop="organisation.data"
                            :index-prop="index"
                            :calculation-tool-id-prop="calculationToolIdProp"
                            :current-step-prop="currentStepProp"
                        />
                    </li>
                </ul>

                <div class="next-step-action-wrapper flex justify-center">
                    <button
                        class="inline-block mx-auto py-3 px-8 rounded-xl font-medium text-white bg-pso-orange"
                        dusk="btn-next-step"
                        @click.prevent="goToTheNextStep()"
                    >
                        {{ translate('application.summary.next_button') }}
                    </button>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
import CalculationTool from '../../../CalculationTool/CalculationTool';
import CalculationToolHeader from "../../../CalculationTool/Parts/CalculationToolHeader/Vue/CalculationToolHeader.vue";
import OrganisationInSummary from "../../../Organisation/Parts/OrganisationInSummary/Vue/OrganisationInSummary.vue"
import BackLinkButton from '../../../Templates/Parts/BackLinkButton/Vue/BackLinkButton.vue';
import {mapGetters} from 'vuex';
import _ from "lodash";
import {getSteps} from "../../../_config/Steps";

export default {
    components: {OrganisationInSummary, CalculationToolHeader, 'app-back-button': BackLinkButton},
    props: {
        calculationToolIdProp: {
            type: Number,
            default: null,
        },
        currentStepProp: {
            type: Number,
            required: true,
        }
    },
    data() {
        return {
            calculationToolId: this.calculationToolIdProp,
            organisationsList: [],
            logoUpload: null,
            errors: [],
            mainOrganisation: null,
            iconPlusGray: new URL('/resources/images/icon_plus_gray.svg', import.meta.url).href,
        };
    },
    computed: {
        ...mapGetters("calculation_tool", {receivedOrganisationsList: "organisations_list"}),
    },
    watch: {
        receivedOrganisationsList() {
            this.organisationsList = this.receivedOrganisationsList;
            this.mainOrganisation = _.first(_.filter(this.organisationsList, function(organisation) { return organisation.is_main; }));
        },
    },
    mounted() {
        CalculationTool.get(this.calculationToolIdProp);
        CalculationTool.getOrganisations(this.calculationToolIdProp);
    },
    methods: {
        previousStep() {
            window.location = route('calculation-tool-application.additional-information', {calculationTool: this.calculationToolId, organisationId: this.mainOrganisation.id});
        },

        goToTheNextStep() {
            CalculationTool.updateStep({calculationTool: this.calculationToolIdProp, step: getSteps().final_request})
                .then((response) => {
                    if (response.status === 200) {
                        window.location = route('calculation-tool-application.request-selection', {calculationTool: this.calculationToolId});
                    }
                }).catch((error) => {
                    console.log(error.response.data.data.message);
                });
        },
    }
};
</script>
