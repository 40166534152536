<template>
    <div class="auditor-select-box">
        <div
            v-for="(auditorCompany) in auditors"
            :key="auditorCompany.id"
            class="w-1/2 inline-block even:pl-2 odd:pr-2 mb-4"
        >
            <div
                :class="
                    (isSelectedAuditor(auditorCompany))
                        ? 'border-blue-800 bg-blue-200'
                        : 'border-gray-200'
                "
                class="radio-button-wrapper w-full flex flex-row-reverse justify-end border rounded-l"
            >
                <label
                    :for="'selected-auditor-'+auditorCompany.id"
                    class=" text-black items-center mb-0 pl-4 pt-4 pb-5 pl-3 pr-5 hover:cursor-pointer font-normal text-medium w-full"
                    :dusk="'selected-auditor-'+auditorCompany.id"
                >
                    <p class="font-medium uppercase text-lg">{{ auditorCompany.name }}</p>
                    <div class="w-full mt-6 text-gray-1000 flex">
                        <div class="mr-4 text-gray-900">
                            {{ translate('application.final_request_auditors.auditor_contact') }}
                        </div>
                        <div>
                            <p class="mb-3">{{ auditorCompany.main_contact_name }}</p>
                            <p class="mb-3">{{ auditorCompany.email }}</p>
                            <p>{{ auditorCompany.phone }}</p>
                        </div>
                    </div>
                    <p class="w-full block text-md mt-6 text-gray-1000">
                        {{ translate('application.final_request_auditors.more_info_about_auditor') }} {{ auditorCompany.name }}?
                        <a
                            :href="auditorCompany.website.startsWith('http') ? auditorCompany.website : `http://${auditorCompany.website}`"
                            target="_blank"
                            class="ml-4 text-blue-1000 underline"
                        >
                            {{ translate('application.final_request_auditors.click_here') }}
                        </a>
                    </p>
                </label>
                <input
                    :id="'selected-auditor-'+auditorCompany.id"
                    v-model="selectedAuditors"
                    :value="auditorCompany.id"
                    class="appearance-none h-4 w-4 border border-gray-200 bg-white text-blue-800 checked:border-blue-800 focus:outline-none transition duration-200 mt-5 align-top bg-no-repeat bg-center bg-contain float-left ml-6 cursor-pointer "
                    name="selectedAuditors"
                    :type="isSingleAuditor ? 'radio' : 'checkbox'"
                >
            </div>
        </div>
    </div>
</template>

<script>
import CalculationTool from '../../../../CalculationTool/CalculationTool';
import {mapGetters} from "vuex";
import _ from "lodash";
import Auditor from "../../../Auditor";

export default {
    props: {
        calculationToolIdProp: {
            type: Number,
            required: true
        },
        isSingleAuditorProp: {
            type: Boolean,
            required: true
        },
    },
    data() {
        return {
            selectedAuditors: [],
            isSingleAuditor: this.isSingleAuditorProp
        };
    },
    computed: {
        ...mapGetters("auditor", ["auditors"]),
        ...mapGetters("calculation_tool", {calculationToolAuditors: "auditors_list"}),
    },

    watch: {
        selectedAuditors() {
            window.mitt.emit("actionGetSelectedAuditors", this.selectedAuditors);
        },
        auditors() {
            window.mitt.emit('actionGetAuditors', this.auditors);
        }
    },
    mounted() {
        Auditor.index();
    },
    methods: {
        isSelectedAuditor(auditor) {
            if (Array.isArray(this.selectedAuditors) && !this.isSingleAuditor) {
                return this.selectedAuditors.includes(auditor.id);
            }

            if (Array.isArray(this.selectedAuditors) && this.isSingleAuditor) {
                return false;
            }

            return this.selectedAuditors === auditor.id;
        }
    }
};
</script>
